import { getLanguage } from '../user/device-info';
import hasUserProvidedEmail from '../user/has-provided-email';
import { parseEscapedJSONString } from '../json/parse';

function updateState( element, action, className ) {
    const replacable = element.querySelector( '.text' ) || element;

    if ( action === 'save' && !element.classList.contains( className ) ) {
        element.dataset.saveSave = replacable.innerHTML;
        replacable.innerHTML = element.dataset.saveSaved;
        element.classList.add( className );
    }

    if ( action === 'remove' && element.classList.contains( className ) ) {
        replacable.innerHTML = element.dataset.saveSave;
        element.classList.remove( className );
    }

    return function() {
        if ( action === 'save' && element.classList.contains( className ) ) {
            replacable.innerHTML = element.dataset.saveSave;
            element.classList.remove( className );
        }

        if ( action === 'remove' && !element.classList.contains( className ) ) {
            element.dataset.saveSave = replacable.innerHTML;
            replacable.innerHTML = element.dataset.saveSaved;
            element.classList.add( className );
        }
    };
}

function saveFromElement() {
    var save = async function( el, forceSave, data = {} ) {
        if ( forceSave && typeof window.savedSearch !== 'undefined' && window.savedSearch ) {
            return;
        }

        if ( el.disabled ) {
            return;
        }

        el.disabled = true;

        var action;
        var favClassName;

        if ( typeof window.savedSearch !== 'undefined' && !window.savedSearch && forceSave ) {
            action = 'save';
            favClassName = 'favourite';
        } else {
            favClassName = el.classList.contains( 'favourite-button' ) ? 'favourite' : 'favourite-alt';
            action = el.classList.contains( favClassName ) ? 'remove' : 'save';
        }

        const undoStateChange = updateState( el, action, favClassName );

        let formData = {
            property: '',
            value: '',
            meta: '',
            displayName: '',
        };

        if ( data.saveProperty && data.saveValue && data.saveMeta ) {
            formData.property = data.saveProperty;
            formData.value = data.saveValue;
            formData.meta = data.saveMeta;
        } else {
            formData.property = el.dataset.saveProperty;
            formData.value = el.dataset.saveValue;
            formData.meta = el.dataset.saveMeta;
        }

        const displayName = data.saveDisplayname;

        if ( formData.property === 'property-search' && displayName && displayName.length > 0 ) {
            formData.displayName = displayName;
        }

        var langPrefix = '';

        var lang = getLanguage();

        if ( lang !== 'en' ) {
            langPrefix = '/' + lang;
        }

        var isPropertySearchBeingSaved = action === 'save' && ( formData.property === 'property-search' );
        var isPropertyBeingSaved = action === 'save' && formData.property === 'property';
        var isPropertyBeingUnSaved = action !== 'save' && formData.property === 'property';

        if ( isPropertySearchBeingSaved ) {
            window.savedSearch = true;
        }

        if ( formData.property === 'property-search' && action === 'remove' ) {
            window.savedSearch = false;
        }

        var url = new URL( window.location.origin + langPrefix + '/saved/' + action );

        var elDescription = 'tag:' + el.tagName + ',id:' + el.id + ',class:' + el.className;
        // property & value are required
        // displayName & meta are not
        var form = new FormData();
        form.append( 'property', formData.property );
        form.append( 'value', formData.value );

        if ( formData.displayName ) {
            form.append( 'displayName', formData.displayName );
        }

        if ( formData.meta ) {
            form.append( 'meta', formData.meta );
        }

        form.append( 'el', elDescription );

        const response = await window.http( url, {
            method: 'POST',
            body: form,
        } );

        if ( !response.ok ) {
            undoStateChange();
            el.disabled = false;
            throw new Error( 'failed saving' );
        }

        window.dispatchEvent( new CustomEvent( 'twc:update-saved-counter', {
            bubbles: true,
            detail: {
                delta: action === 'save' ? 1 : -1,
            },
        } ) );

        var savables = document.getElementsByClassName( 'twc__savable' );

        for ( var i = 0; i < savables.length; i++ ) {
            if ( !savables[ i ].dataset.saveProperty || savables[ i ].dataset.saveProperty !== formData.property ) {
                continue;
            }
            if ( savables[ i ].dataset.saveValue !== formData.value ) {
                continue;
            }

            updateState( savables[ i ], action, favClassName );
        }

        if ( ( isPropertySearchBeingSaved || isPropertyBeingSaved ) && !forceSave ) {
            hasUserProvidedEmail()
                .then( function( hasProvidedEmail ) {
                    if ( hasProvidedEmail ) {
                        return;
                    }

                    const eventDetail = {
                        id: '',
                    };

                    if ( isPropertyBeingSaved ) {
                        eventDetail.id = 'save-property';
                    }

                    if ( isPropertySearchBeingSaved ) {
                        eventDetail.id = 'save-search';
                    }

                    document.dispatchEvent( new CustomEvent( 'twc:popup:open-property-alert', {
                        bubbles: true,
                        detail: eventDetail,
                    } ) );
                } );
        }

        const hasAddToCartEvent = el.dataset.addToCartEvent && el.dataset.addToCartEvent.length > 0;
        const hasRemoveFromCartEvent = el.dataset.removeFromCartEvent && el.dataset.removeFromCartEvent.length > 0;

        const updateEventListValues = function( event, elementEventSelector, elementEventAttr ) {
            const matchingElementWithEvent = Array.from( document.querySelectorAll( elementEventSelector ) ).find( ( element ) => {
                if ( element.closest( '#page-popup-container' ) ) {
                    return false;
                }

                const parsed = parseEscapedJSONString( element.dataset[ elementEventAttr ] );

                return parsed.ecommerce.items[ 0 ].item_id === event.ecommerce.items[ 0 ].item_id;
            } );

            if ( !matchingElementWithEvent ) {
                return event;
            }

            const matchingEvent = parseEscapedJSONString( matchingElementWithEvent.dataset[ elementEventAttr ] );

            // eslint-disable-next-line camelcase
            event.ecommerce.items[ 0 ].item_list_id = matchingEvent.ecommerce.items[ 0 ].item_list_id;

            // eslint-disable-next-line camelcase
            event.ecommerce.items[ 0 ].item_list_name = matchingEvent.ecommerce.items[ 0 ].item_list_name;

            return event;
        };

        if ( isPropertyBeingSaved && hasAddToCartEvent ) {
            let event = parseEscapedJSONString( el.dataset.addToCartEvent );

            if ( event.ecommerce.items[ 0 ].item_list_id === 'no_list_available' ) {
                event = updateEventListValues( event, '[data-add-to-cart-event]', 'addToCartEvent' );
                el.dataset.addToCartEvent = JSON.stringify( event );
            }

            window.dataLayerPush( { ecommerce: null } );
            window.dataLayerPush( event );
        }

        if ( isPropertyBeingUnSaved && hasRemoveFromCartEvent ) {
            let event = parseEscapedJSONString( el.dataset.removeFromCartEvent );

            if ( event.ecommerce.items[ 0 ].item_list_id === 'no_list_available' ) {
                event = updateEventListValues( event, '[data-remove-from-cart-event]', 'removeFromCartEvent' );
                el.dataset.removeFromCartEvent = JSON.stringify( event );
            }

            window.dataLayerPush( { ecommerce: null } );
            window.dataLayerPush( event );
        }

        if ( isPropertySearchBeingSaved && el.dataset.addToWishlistEvent && el.dataset.addToWishlistEvent.length > 0 ) {
            window.dataLayerPush( { ecommerce: null } );
            window.dataLayerPush( parseEscapedJSONString( el.dataset.addToWishlistEvent ) );
        }

        var event = el.dataset.saveEvent;

        if ( action === 'save' && event ) {
            window.dataLayerPush( JSON.parse( event ) );
        }

        var savedEvent = {
            saved: action === 'save',
            type: formData.property,
            meta: {},
            value: null,
        };

        if ( formData.meta && formData.meta.length > 0 ) {
            savedEvent.meta = JSON.parse( formData.meta );
        }

        if ( /-search/i.test( formData.property ) ) {
            savedEvent.value = JSON.parse( formData.value );
        } else {
            savedEvent.value = parseInt( formData.value );
        }

        document.dispatchEvent( new CustomEvent( 'twc:update-saved-status', {
            bubbles: true,
        } ) );

        document.dispatchEvent( new CustomEvent( 'twc:saved-change', {
            bubbles: true,
            detail: savedEvent,
        } ) );

        el.disabled = false;
    };

    window.addEventListener( 'twc:saveable', function( event ) {
        if ( !event.detail ) {
            return;
        }
        save( event.detail.element, event.detail.forceSave, event.detail.data );
    } );

    document.addEventListener( 'click', function( event ) {
        if ( !event.target || !( 'saveable' in event.target.dataset ) ) {
            return;
        }

        event.preventDefault();

        var requiresHandler = (
            event.target.dataset.requiresHandler &&
            event.target.dataset.requiresHandler === 'true'
        );

        if ( requiresHandler ) {
            event.target.dispatchEvent( new CustomEvent( 'twc-save:' + event.target.dataset.handler, {
                bubbles: true,
            } ) );
            return;
        }

        save( event.target, false );
    } );
}

export default saveFromElement;
