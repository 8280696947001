import getIcon from '../svgs';
import { getPagePopupContainer } from './page-popup';

export default function showErrorPopup() {
    const errormsg = `<a href="${window.router.previousState.url}" class="pagepopup__close d-flex flex-align-center flex-justify-center">${getIcon( 'closeX', 'white' )}</a><div class="header text-center"><h3 class="mb-none">An error ocurred</h3></div><div class="single-content mt-xlg mb-xlg text-center">Sorry, we are unable to handle your request</div>`;

    const popupContainer = getPagePopupContainer();

    const popupContent = popupContainer.querySelector( '.pagepopup__content' );

    popupContent.innerHTML = errormsg;

    popupContainer.classList.remove( 'pagepopup__wrapper--loading' );
    popupContainer.classList.add( 'pagepopup__wrapper--open' );
    document.body.classList.add( 'page-popup-open' );
}
