/**
 * @param {String} name - the name of the cookie
 * @returns {String} the cookie
 */
function readCookie( name ) {
    var nameEQ = name + '=';
    var ca = document.cookie.split( ';' );

    for ( var i = 0; i < ca.length; i++ ) {
        var c = ca[ i ];

        while ( c.charAt( 0 ) === ' ' ) {
            c = c.substring( 1, c.length );
        }

        if ( c.indexOf( nameEQ ) === 0 ) {
            return c.substring( nameEQ.length, c.length );
        }
    }

    return null;
}

var defaultAttributes = {
    'path': '/',
    'SameSite': 'Lax',
    'Secure': '',
};

/**
 * @param {String} name - the name to store the cookie under
 * @param {String} value - the value of the cookie
 * @param {Number} - the number days to last
 */
function createCookie( name, value, days = 0 ) {
    var attributes = Object.assign( {}, {
        [ name ]: value,
    }, defaultAttributes );

    if ( days > 0 ) {
        var date = new Date();
        date.setTime( date.getTime() + days * 24 * 60 * 60 * 1000 );
        attributes[ 'expires' ] = date.toUTCString();
    }

    var attributesJoined = [];

    Object.keys( attributes ).forEach( function( attr ) {
        if ( attributes[ attr ].length > 0 ) {
            attributesJoined.push( attr + '=' + attributes[ attr ] );
        } else {
            attributesJoined.push( attr );
        }
    } );

    var cookie = attributesJoined.join( ';' );

    document.cookie = cookie;
}

/**
 * @param {String} name - the name of the cookie to remove
 */
function eraseCookie( name ) {
    createCookie( name, '', -1 );
}

function setLastSearchResultPageCookie() {
    createCookie( 'lv', document.location.href, 14 );
}

export {
    createCookie,
    readCookie,
    eraseCookie,
    setLastSearchResultPageCookie,
};
