import { getLanguage } from './device-info';
import { En, De, Es } from '../languages';
import { closeAllUtilityPopups, closeSideNav, openUtilityPopup } from '../popups/utils';
import triggerEvent, { EventNameMailshotUnSubscribe, GA4EventParameters } from '../analytics/events';
import { PropertyAlertNotificationFrequency } from '../property/alerts';

const languagesToTrack = [ En, De, Es ];

export type EmailType = 'newsletter' | 'partner' | 'featured-property' | 'property-alert';

export const EmailTypeFeaturedProperties: EmailType = 'featured-property';

export const EmailTypePropertyAlert: EmailType = 'property-alert';

export const EmailTypeNewsletter: EmailType = 'newsletter';

export const EmailTypePartner: EmailType = 'partner';

export type OpenUserUnsubscribePopupEvent = {
    emailTypes: Array<EmailType>;
    emailFrequency: PropertyAlertNotificationFrequency;
}

export function canShowUserUnsubscribePopup(): boolean {
    const language = getLanguage();

    if ( !languagesToTrack.includes( language ) ) {
        return false;
    }

    return true;
}

async function onUserUnsubscribe( event: CustomEvent<OpenUserUnsubscribePopupEvent> ) {
    if ( !canShowUserUnsubscribePopup() ) {
        return;
    }

    const eventParameters: GA4EventParameters = {
        'email_types': event.detail.emailTypes.sort().join( ',' ),
        'email_frequency': event.detail.emailFrequency,
        'email_unsubscribe_reason': 'no-reason',
    };

    let prefix = '';

    const language = getLanguage();

    if ( language !== 'en' ) {
        prefix += '/' + language;
    }

    const url = new URL( window.location.origin + prefix + '/user/unsubscribe' );

    const onFormAdded = function( popup: HTMLElement ): void {
        const form =  popup.querySelector( 'form' ) as HTMLFormElement;
        const otherReasonInfo = form.querySelector( '#unsubscribe-other-reason-info' ) as HTMLTextAreaElement;
        const inputs = form.getElementsByTagName( 'input' );
        const msgs = form.querySelector( '.messages' ) as HTMLElement;

        const removePopup = function( doClose: boolean = false ) {
            if ( doClose ) {
                closeAllUtilityPopups();
            }

            popup.remove();

            if ( !form.dataset.submitted ) {
                triggerEvent( EventNameMailshotUnSubscribe, eventParameters );
            }
        };

        popup.addEventListener( 'twc:popup:close-utility-popup', () => removePopup() );

        for ( let i = 0; i < inputs.length; i++ ) {
            inputs[ i ].addEventListener( 'change', function( event: Event ) {
                if ( ( event.target as HTMLInputElement ).value === 'other-reason' ) {
                    otherReasonInfo.classList.toggle( 'd-none' );
                } else {
                    otherReasonInfo.classList.add( 'd-none' );
                }
            } );
        }

        form.addEventListener( 'submit', function( event: SubmitEvent ) {
            event.preventDefault();

            if ( form.dataset.submitted ) {
                removePopup();
                return;
            }

            let reason = 'no-reason';

            msgs.innerHTML = '';

            const selectedReason = popup.querySelector( 'input[name=unsubscribe-reason]:checked' ) as HTMLInputElement;

            if ( selectedReason ) {
                reason = selectedReason.value;
            }

            const txtAreaValue = otherReasonInfo.value.trim();

            if ( reason === 'other-reason' && txtAreaValue.length === 0 ) {
                const msg = otherReasonInfo.dataset.errorMessageRequired;
                msgs.insertAdjacentHTML( 'beforeend', '<div class="alert alert-warning">' + msg + '</div>' );
                return;
            }

            let unsubscribeReason = reason;

            if ( reason === 'other-reason' ) {
                unsubscribeReason = `other-reason: "${txtAreaValue}"`;
            }

            triggerEvent( EventNameMailshotUnSubscribe, {
                ...eventParameters,
                'email_unsubscribe_reason': unsubscribeReason,
            } );

            form.dataset.submitted = 'true';

            removePopup( true );
        } );
    };

    const response = await window.http( url );

    if ( !response.ok ) {
        throw new Error();
    }

    const popupContainer = document.querySelector( '#utility-popup-container' ) as HTMLElement;
    popupContainer.insertAdjacentHTML( 'beforeend', ( await response.text() ) );

    const popup = document.querySelector( '.popup__container--email-unsubscribe' ) as HTMLElement;

    await closeSideNav();

    closeAllUtilityPopups( false );

    openUtilityPopup( popup );

    onFormAdded( popup );
}

export default onUserUnsubscribe;
