import { navigateTo } from '../../http/redirect';
import { isMapMode } from '../../maps/util';
import { getOfferTypeForForm } from '../../property/search-filters';
import Typeahead, { AwesompleteEvent } from '../typeahead';
import { Suggestion, createBaseLI } from './common';

class CurrentLocationSuggestion implements Suggestion {
    label: string;
    value: string;
    urls: Record<string, string>;
    icon: string;
    suggestionType = 'current-location';
    allowedCountries?: Array<string>;
    _item?: HTMLLIElement;

    select( ctx: Typeahead, event: AwesompleteEvent ): boolean {
        ctx._awesomeplete.close();

        ctx._input.blur();

        if ( ctx._reset ) {
            ctx._reset.block( true );
        }

        if ( !isMapMode() ) {
            window.navigator.geolocation.getCurrentPosition( () => {
                navigateTo( this.urls[ getOfferTypeForForm( ctx._form ) ] );
            } );
        } else {
            ctx._input.dispatchEvent( new CustomEvent( 'twc:mapsearch:geolocate-activate', { bubbles: true } ) );
        }

        return false;
    }

    selected( ctx: Typeahead ): void {
        return;
    }

    item( highlightElement: HTMLElement, ref: string, index: number ): HTMLLIElement {
        if ( !this._item ) {
            this._item = createBaseLI( ref, index, this );
            this._item.dataset.id = 'current-location';
            this._item.appendChild( highlightElement );
        }
        return this._item;
    }
}

export default function newCurrentLocationSuggestion( obj: any ): CurrentLocationSuggestion {
    const s = new CurrentLocationSuggestion();
    s.value = obj.value || obj.id || '';
    s.label = obj.name || obj.label || '';
    s.urls = obj.urls || '';
    s.icon = obj.icon || '';
    s.allowedCountries = obj.allowedCountries || [];
    return s;
}
