import Pristine from 'pristinejs';
import { isVisible } from '../dom/visibility';
import { validateEmailAddress } from './email';

const ValidatorPriorityHighest: number = 100;

export default function newValidator( form: HTMLFormElement ): Pristine {
    const validator = new Pristine( form, {
        // class of the parent element where the error/success class is added
        classTo: 'form-group',
        errorClass: 'pristine-has-danger',
        successClass: 'pristine-has-success',
        // class of the parent element where error text element is appended
        errorTextParent: 'form-group',
        // type of element to create for the error text
        errorTextTag: 'label',
        // class of the error text element
        errorTextClass: 'error-message',
    }, true );

    /**
     * Custom validators must return a boolean.
     * True will pass the validation
     * False will fail the validation
     */
    form.querySelectorAll( '[data-pristine-email-strict]' ).forEach( ( input: HTMLInputElement ) => {
        validator.addValidator( input, function( value: string ) {
            if ( input.required ) {
                return validateEmailAddress( value );
            }
            if ( !input.required && value && value.length > 0 ) {
                return validateEmailAddress( value );
            }
            return true;
        }, input.dataset.pristineEmailStrictMessage || input.dataset.pristineEmailMessage, ValidatorPriorityHighest, true );
    } );

    form.querySelectorAll( '[data-pristine-username]' ).forEach( ( input: HTMLInputElement ) => {
        validator.addValidator( input, function( value: string ) {
            return !new RegExp( '[0-9@:;,./\\\\#!"%()=?*_<>]' ).test( value );
        }, input.dataset.pristineUsernameMessage, ValidatorPriorityHighest, true );
    } );

    form.querySelectorAll( '[data-pristine-money]' ).forEach( ( input: HTMLInputElement ) => {
        validator.addValidator( input, function( value: string ) {
            return /^[0-9]*$/.test( value );
        }, input.dataset.pristineMoneyMessage, ValidatorPriorityHighest, true );
    } );

    form.querySelectorAll( '[data-pristine-required-if-visible]' ).forEach( ( input: HTMLInputElement ) => {
        validator.addValidator( input, function( value: string ) {
            if ( !isVisible( this ) ) {
                return true;
            }
            return !!value;
        }, input.dataset.pristineRequiredIfVisibleMessage, ValidatorPriorityHighest, true );
    } );

    form.querySelectorAll( '[data-pristine-required-enquiry-msg]' ).forEach( ( input: HTMLTextAreaElement ) => {
        validator.addValidator( input, function( value: string ) {
            const parentForm = this.closest( 'form' );

            if ( !parentForm ) {
                throw new Error( 'failed to find parent form for input' );
            }

            const checkboxes: Array<HTMLInputElement> = Array.from( parentForm.querySelectorAll( '.enquiry__checkboxes input[type="checkbox"]' ) );

            if ( checkboxes.length === 0 ) {
                throw new Error( 'failed to find cta checkboxes' );
            }

            const anyTicked = checkboxes.some( ( input: HTMLInputElement ) => input.checked );
            const hasValue = value.length > 0;

            if ( anyTicked || hasValue ) {
                return true;
            }

            return false;
        }, input.dataset.pristineRequiredEnquiryMsgMessage, ValidatorPriorityHighest, true );
    } );

    return validator;
}
