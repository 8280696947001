function updateAlertsCounter( delta: number ): void {
    const counters = document.querySelectorAll<HTMLElement>( '.alerts-counter' );

    if ( counters.length === 0 ) {
        return;
    }

    let currentValue = parseInt( counters[ 0 ].innerText );

    if ( isNaN( currentValue ) ) {
        currentValue = 0;
    }

    counters.forEach( ( el: HTMLElement ) => el.innerText = ( currentValue + delta ).toString() );
}

async function updateAlertsStatus(): Promise<void> {
    const response = await window.http( '/alert/count' );

    const body = await response.json();

    if ( body.success && body.data ) {
        document.querySelectorAll<HTMLElement>( '.alerts-counter' ).forEach( ( el: HTMLElement ) => el.innerText = body.data.count );
    }
}

function disableAlertHandler(): void {
    document.addEventListener( 'click', function( event: Event ) {
        if ( !event.target || !( event.target instanceof HTMLElement ) || !event.target.closest( '.p-alert.enabled .alert-disable' ) ) {
            return;
        }

        event.preventDefault();

        const el = event.target.closest<HTMLElement>( '[data-alert-id]' );

        if ( !el ) {
            return;
        }

        const formData = new FormData();
        formData.append( 'id', el.dataset.alertId || '' );

        window.http( '/alert/disable', {
            method: 'POST',
            body: formData,
        } )
            .then( function( response: Response ) {
                return response.json();
            } )
            .then( function( result ) {
                if ( result.success ) {
                    el.classList.add( 'disabled' );
                    el.classList.remove( 'enabled' );
                    updateAlertsCounter( -1 );
                }
            } );
    } );
}

function enableAlertHandler(): void {
    document.addEventListener( 'click', function( event: Event ) {
        if ( !event.target || !( event.target instanceof HTMLElement ) || !event.target.closest( '.p-alert.disabled .alert-enable' ) ) {
            return;
        }

        event.preventDefault();

        const el = event.target.closest<HTMLElement>( '[data-alert-id]' );

        if ( !el ) {
            return;
        }

        const formData = new FormData();
        formData.append( 'id', el.dataset.alertId || '' );

        window.http( '/alert/enable', {
            method: 'POST',
            body: formData,
        } )
            .then( function( response: Response ) {
                return response.json();
            } )
            .then( function( result ) {
                if ( result.success ) {
                    el.classList.remove( 'disabled' );
                    el.classList.add( 'enabled' );
                    updateAlertsCounter( +1 );
                }
            } );
    } );
}

function disableAlert( alertID: number ): void {
    if ( !alertID ) {
        return;
    }

    window.router.CaptureThen( function() {
        const state = window.router.currentState;
        state.url = window.location.href.split( '?' )[ 0 ];
        window.router.Replace( 'current', state );
    } );

    const buttonYes = document.querySelector( '.disable-alert-confirmation .answer-yes button' );

    if ( buttonYes ) {
        buttonYes.addEventListener( 'click', function() {
            const formData = new FormData();
            formData.append( 'id', alertID.toString() );

            window.http( '/alert/disable', {
                method: 'POST',
                body: formData,
            } )
                .then( function( response: Response ) {
                    return response.json();
                } )
                .then( function( result ) {
                    if ( result.success ) {
                        const alertInfoElement = document.querySelector( '[data-alert-id="' + alertID + '"]' );
                        if ( alertInfoElement ) {
                            alertInfoElement.classList.add( 'disabled' );
                            alertInfoElement.classList.remove( 'enabled' );
                        }
                    }

                    const disableAlertConfirmation = document.querySelector( '.disable-alert-confirmation' );

                    if ( disableAlertConfirmation ) {
                        disableAlertConfirmation.remove();
                    }
                } );
        } );
    }

    const buttonNo = document.querySelector( '.disable-alert-confirmation .answer-no button' );

    if ( buttonNo ) {
        buttonNo.addEventListener( 'click', function( event ) {
            event.preventDefault();

            const disableAlertConfirmation = document.querySelector( '.disable-alert-confirmation' );

            if ( disableAlertConfirmation ) {
                disableAlertConfirmation.remove();
            }
        } );
    }
}

export {
    updateAlertsCounter,
    updateAlertsStatus,
    disableAlert,
    enableAlertHandler,
    disableAlertHandler,
};
