import { FilterCount, countFilters, countLocationFilters } from './filter-count';
import { resetSearchForm } from './filters';

function propertySearchFiltersAsURLSearchParams(): URLSearchParams {
    const searchForm = document.querySelector( '#property-search-form-base' ) as HTMLFormElement;
    const formData = new FormData( searchForm );
    const searchParams = new URLSearchParams();

    formData.forEach( function( value, key ) {
        if ( !value || value.toString().length === 0 ) {
            return;
        }
        searchParams.set( key, value.toString() );
    } );

    const tags = Array.from( document.querySelectorAll<HTMLInputElement>( '[name=tags]:checked' ) ).map( function( el ) {
        return el.value;
    } );

    if ( tags.length > 0 ) {
        searchParams.set( 'tags', tags.join( ',' ) );
    }

    const propertyTypes = Array.from( searchForm.querySelectorAll<HTMLInputElement>( '[name=types]' ) ).map( function( el ) {
        return el.value;
    } );

    if ( propertyTypes.length > 0 ) {
        searchParams.set( 'types', propertyTypes.join( ',' ) );
    }

    return searchParams;
}

type PriceValue = {
    text: string;
    value: number;
}

function updatePricesForOfferType( form: HTMLFormElement, offerType: string, dispatchChangeEvent: boolean ): void {
    const nameMapping = {
        'minprice': 'min',
        'maxprice': 'max',
    };

    form.querySelectorAll( 'select[name=minprice],select[name=maxprice]' ).forEach( ( priceSelect: HTMLSelectElement ) => {
        for ( let i = priceSelect.length - 1; i >= 0; i-- ) {
            if ( priceSelect.options[ i ].value ) {
                priceSelect.remove( i );
            }
        }

        window.prices[ offerType ][ nameMapping[ priceSelect.name ] ].forEach( ( keyValue: PriceValue ) => {
            const option = document.createElement( 'option' );
            option.text = keyValue.text;
            option.value = keyValue.value.toString();
            priceSelect.add( option );
        } );

        priceSelect.value = '';

        priceSelect.classList.remove( 'has-value' );

        if ( dispatchChangeEvent ) {
            priceSelect.dispatchEvent( new Event( 'change' ) );
        }
    } );
}

type TagValidationResult = {
    show: boolean,
    forceUncheck: boolean
}

function updateTagsValidityOnForm( form: HTMLFormElement, changedTagElement?: HTMLInputElement ): void {
    const offerType = ( form.querySelector( '[name="offer"]' ) as HTMLSelectElement ).value;
    const propertyTypes = Array.from( form.querySelectorAll<HTMLInputElement>( 'input[name=types]:checked,input[name=property-type]:checked' ) ).map( ( input: HTMLInputElement ) => input.value );

    let checkedTagName = '';

    if ( changedTagElement && changedTagElement.checked ) {
        checkedTagName = changedTagElement.value;
    }

    const isValid = function( tag: string ): TagValidationResult {
        switch ( tag ) {
        case 'cheap':
        case 'luxury':
            return {
                show: offerType === 'for-sale' || offerType === 'all',
                forceUncheck: false,
            };
        case 'furnished':
            return {
                show: offerType === 'for-rent' || offerType === 'all',
                forceUncheck: checkedTagName === 'unfurnished',
            };
        case 'unfurnished':
            return {
                show: offerType === 'for-rent' || offerType === 'all',
                forceUncheck: checkedTagName === 'furnished',
            };
        case 'pets-allowed':
            return {
                show: offerType === 'for-rent' || offerType === 'for-holidays' || offerType === 'all',
                forceUncheck: false,
            };
        case 'ground-floor':
            return {
                show: propertyTypes.length === 0 || [ 'houses-and-apartments', 'apartments' ].includes( propertyTypes[ 0 ] ),
                forceUncheck: false,
            };
        }

        return {
            show: true,
            forceUncheck: false,
        };
    };

    form.querySelectorAll( 'input[name="tags"][type="checkbox"]' ).forEach( ( element: HTMLInputElement ) => {
        const valid = isValid( element.value );

        const parentElement = element.parentNode as HTMLElement | null;

        if ( parentElement === null ) {
            throw new Error( 'parentElement missing' );
        }

        if ( valid.show ) {
            parentElement.style.display = '';

            if ( valid.forceUncheck ) {
                element.checked = false;
            }
        } else {
            element.checked = false;
            parentElement.style.display = 'none';
        }
    } );
}

function syncLocationInputs( from: HTMLInputElement, to: HTMLFormElement ): void {
    const targetInput: HTMLInputElement | null = to.querySelector( '.searchpopup__location input[name="locationId"]' );

    if ( !targetInput ) {
        return;
    }

    targetInput.value = from.value;
    targetInput.dataset.isIsland = from.dataset.isIsland || '';
    targetInput.dataset.isGeocoded = from.dataset.isGeocoded || '';
    targetInput.dataset.longitude = from.dataset.longitude || '';
    targetInput.dataset.latitude = from.dataset.latitude || '';
    targetInput.dataset.id = from.dataset.id || '';
    targetInput.dataset.type = from.dataset.type || '';
    targetInput.dataset.location = from.dataset.location || '';

    const resetContainer = ( targetInput.closest( '[data-typeahead]' ) as HTMLElement ).querySelector( '.reseteable' );

    if ( !resetContainer ) {
        throw new Error( 'expected locationId input to be resetable' );
    }

    const resetButton: HTMLAnchorElement | null = resetContainer.querySelector( '.reset-field' );

    if ( !resetButton ) {
        throw new Error( 'expected to find reset button in reset container' );
    }

    if ( from.value.length === 0 ) {
        resetButton.style.display = 'none';
    } else {
        resetButton.style.display = '';
    }
}

/**
 * @param {Element} from - the <form> element to sync from
 * @param {Element} to - the <form> element to sync to
 */
function syncPropertySearchForms( from: HTMLFormElement, to: HTMLFormElement ): void {
    if ( from.dataset.propertyCount && from.dataset.propertyCount.length > 0 ) {
        to.dataset.propertyCount =  from.dataset.propertyCount as string;
    }

    if ( from.dataset.locationCount && from.dataset.locationCount.length > 0 ) {
        to.dataset.locationCount = from.dataset.locationCount as string;
    }

    resetSearchForm( to, [ 'locationId', 'locationFreeText' ] );

    const baseOfferType = from.querySelector( '[name="offer"]' ) as HTMLInputElement;
    const destinationOfferType = to.querySelector( '[name="offer"]' ) as HTMLInputElement;

    const offerIsSynced = destinationOfferType.value === baseOfferType.value;

    destinationOfferType.value = baseOfferType.value;

    if ( !offerIsSynced ) {
        updatePricesForOfferType( to, baseOfferType.value, false );
    }

    const elementsToAdd: Array<HTMLElement> = [];

    const handledInputs: Record<string, ( from: HTMLInputElement, to: HTMLFormElement ) => void> = {
        'locationId': syncLocationInputs,
    };

    const neverSync = [
        'source',
        'destination',
        'geoSimpleSelect',
        'fypFilterIsModeInterchangable',
    ];

    let sourceInputs: Array<HTMLInputElement> = Array.from( from.querySelectorAll( 'input' ) );

    sourceInputs = sourceInputs.filter( ( input: HTMLInputElement ) => {
        if ( !input.name ) {
            return false;
        }
        if ( neverSync.includes( input.name ) ) {
            return false;
        }
        if ( input.closest( '[disabled]' ) ) {
            return false;
        }
        return true;
    } );

    sourceInputs.forEach( ( input: HTMLInputElement ) => {
        if ( Object.keys( handledInputs ).includes( input.name ) ) {
            if ( to.querySelector( `[name="${input.name}"]` ) ) {
                handledInputs[ input.name ]( input, to );
            }
            return;
        }

        const matchingDestinations: NodeListOf<HTMLInputElement> = to.querySelectorAll( `input[name="${input.name}"]` );

        let matchingDestination: HTMLInputElement | null = to.querySelector( `input[name="${input.name}"]` );

        if ( matchingDestination && matchingDestination.name.length === 0 ) {
            return;
        }

        if ( matchingDestination && matchingDestination.dataset.sync && matchingDestination.dataset.sync === 'false' ) {
            return;
        }

        if ( matchingDestination !== null && matchingDestination.type === 'hidden' && input.type === 'hidden' ) {
            if ( matchingDestinations.length > 0 ) {
                matchingDestination = to.querySelector( `input[name="${input.name}"][value="${input.value}"]` );

                if ( matchingDestination ) {
                    matchingDestination.value = input.value;
                    return;
                }

                matchingDestinations.forEach( ( input: HTMLInputElement ) => {
                    if ( !input.value ) {
                        matchingDestination = input;
                    }
                } );
            }

            if ( !matchingDestination ) {
                return;
            }

            matchingDestination.value = input.value;
            return;
        }

        if ( matchingDestination && matchingDestination.type === 'hidden' && input.type === 'checkbox' && !input.checked ) {
            matchingDestination = to.querySelector( `input[name="${input.name}"][value="${input.value}"]` );

            if ( matchingDestination ) {
                matchingDestination.remove();
            }
            return;
        }

        if ( matchingDestination === null && input.type === 'checkbox' && input.checked && input.value.length > 0 ) {
            const inputToAdd: HTMLInputElement = document.createElement( 'input' );
            inputToAdd.type = 'hidden';
            inputToAdd.name = input.name;
            inputToAdd.value = input.value;
            elementsToAdd.push( inputToAdd );
            return;
        }

        if ( matchingDestination === null && input.type === 'hidden' ) {
            matchingDestination = to.querySelector( `select[name="${input.name}"]` );

            if ( matchingDestination ) {
                matchingDestination.value = input.value;
                matchingDestination.classList.add( 'has-value' );
            } else {
                const inputToAdd: HTMLInputElement = document.createElement( 'input' );
                inputToAdd.type = 'hidden';
                inputToAdd.name = input.name;
                inputToAdd.value = input.value;
                elementsToAdd.push( inputToAdd );
            }
            return;
        }

        if ( matchingDestination && matchingDestination.type === 'text' ) {
            matchingDestination.value = input.value;
            return;
        }

        if ( matchingDestination && matchingDestination.type === 'checkbox' ) {
            matchingDestination = to.querySelector( `input[name="${input.name}"][value="${input.value}"]` );

            if ( matchingDestination === null ) {
                if ( input.value.length === 0 ) {
                    return;
                }

                const inputToAdd: HTMLInputElement = document.createElement( 'input' );
                inputToAdd.type = 'hidden';
                inputToAdd.name = input.name;
                inputToAdd.value = input.value;
                elementsToAdd.push( inputToAdd );
                return;
            }

            const parentElement: HTMLElement = matchingDestination.parentElement as HTMLElement;

            let check = false;

            if ( input.type === 'hidden' ) {
                check = true;
            } else if ( input.type === 'checkbox' ) {
                check = input.checked;
            }

            if ( check ) {
                matchingDestination.checked = true;

                if ( parentElement.dataset.checkedClass && parentElement.dataset.checkedClass.length > 0 ) {
                    parentElement.classList.add( parentElement.dataset.checkedClass );
                }
            } else {
                matchingDestination.checked = false;

                if ( parentElement.dataset.checkedClass && parentElement.dataset.checkedClass.length > 0 ) {
                    parentElement.classList.remove( parentElement.dataset.checkedClass );
                }
            }
        }
    } );

    const sourceSelects: NodeListOf<HTMLSelectElement> = from.querySelectorAll( 'select' );

    sourceSelects.forEach( ( select: HTMLSelectElement ) => {
        if ( select.name.length === 0 ) {
            return;
        }

        if ( select.closest( '[disabled]' ) ) {
            return;
        }

        if ( neverSync.includes( select.name ) ) {
            return;
        }

        const matchingDestination: HTMLSelectElement | null = to.querySelector( `select[name="${select.name}"]` );

        if ( matchingDestination ) {
            matchingDestination.value = select.value;

            if ( select.value.length > 0 ) {
                matchingDestination.classList.add( 'has-value' );
            } else {
                matchingDestination.classList.remove( 'has-value' );
            }
            return;
        }

        const matchingVisibleDestinations: NodeListOf<HTMLInputElement> = to.querySelectorAll( `input[name="${select.name}"][type="checkbox"],input[name="${select.name}"][type="radio"]` );

        if ( matchingVisibleDestinations.length === 1 ) {
            matchingVisibleDestinations[ 0 ].checked = true;
            return;
        }

        if ( matchingVisibleDestinations.length > 1 ) {
            matchingVisibleDestinations.forEach( ( input: HTMLInputElement ) => {
                if ( input.value === select.value ) {
                    input.checked = true;
                } else {
                    input.checked = false;
                }
            } );
            return;
        }

        const matchingHiddenDestination: HTMLInputElement | null = to.querySelector( `input[name="${select.name}"][type="hidden"]` );

        if ( matchingHiddenDestination ) {
            if ( select.value.length === 0 ) {
                matchingHiddenDestination.remove();
            } else {
                matchingHiddenDestination.value = select.value;
            }
            return;
        }

        if ( select.value.length > 0 ) {
            const inputToAdd: HTMLInputElement = document.createElement( 'input' );
            inputToAdd.type = 'hidden';
            inputToAdd.name = select.name;
            inputToAdd.value = select.value;
            elementsToAdd.push( inputToAdd );
        }
    } );

    elementsToAdd.forEach( ( element: HTMLElement ) => to.appendChild( element ) );

    to.querySelectorAll( '[data-tag-parent-group]' ).forEach( ( container: HTMLLIElement ) => {
        const input = container.querySelector( 'input' ) as HTMLInputElement;

        const parentGroupInput = to.querySelector( `input[value="${container.dataset.tagParentGroup}"]` ) as HTMLInputElement;

        if ( parentGroupInput.checked ) {
            ( input.parentElement as HTMLElement ).classList.remove( 'ts-checktags--hidden' );
        } else {
            ( input.parentElement as HTMLElement ).classList.add( 'ts-checktags--hidden' );
        }
    } );

    to.querySelectorAll( '[data-enabled-by]' ).forEach( ( inputGroup: HTMLFieldSetElement ) => {
        inputGroup.dispatchEvent( new CustomEvent( 'inputgroup:test', {
            bubbles: true,
        } ) );
    } );

    updateTagsValidityOnForm( to );

    updatePropertyTypes( to );
}

function getOfferTypeForForm( form: HTMLFormElement ): string {
    const select = form.querySelector<HTMLSelectElement>( 'select[name=offer]' );

    if ( select ) {
        return select.value;
    }

    const hiddenInput = form.querySelector<HTMLInputElement>( 'input[name=offer][type=hidden]' );

    if ( hiddenInput ) {
        return hiddenInput.value;
    }

    const checkboxOrRadios = form.querySelectorAll<HTMLInputElement>( 'input[name=offer]' );

    if ( checkboxOrRadios.length > 0 ) {
        const selected = Array.from( checkboxOrRadios ).find( ( input: HTMLInputElement ) => {
            return input.checked;
        } );

        if ( selected ) {
            return selected.value;
        }
    }

    return '';
}

function updateResetBtnStates( form: HTMLFormElement ): void {
    const filterCounts: FilterCount = countFilters( form );

    const primaryResetBtn = form.querySelector( '.pagepopup__header button[type=reset]' ) as HTMLButtonElement;

    if ( filterCounts.property.count === 0 ) {
        primaryResetBtn.disabled = true;
    }

    if ( filterCounts.property.count > 0 ) {
        primaryResetBtn.disabled = false;
    }
}

function updateFilterCountBadges( form: HTMLFormElement, locationFilterCount: number, propertyFilterCount: number ): void {
    const locationFiltersContainer = form.querySelector<HTMLFieldSetElement>( '#location-filters-wrapper' );

    if ( locationFilterCount === 0 ) {
        form.querySelectorAll( '.locationsearchfiltercategory .badge' ).forEach( ( badge: HTMLElement ) => badge.classList.add( 'd-none' ) );
    }

    if ( locationFilterCount === 0 && locationFiltersContainer && !locationFiltersContainer.disabled ) {
        form.querySelectorAll( '.locationsearchfiltercategory' ).forEach( ( category: HTMLElement ) => category.dataset.filters = locationFilterCount.toString() );
    }

    if ( locationFilterCount > 0 ) {
        const categories = form.querySelectorAll( '.locationsearchfiltercategory' );

        categories.forEach( ( container: HTMLElement ) => {
            const categoryCount = countLocationFilters( container );
            const badge = container.querySelector( '.badge' );

            if ( locationFiltersContainer && !locationFiltersContainer.disabled ) {
                container.dataset.filters = categoryCount.count.toString();
            }

            if ( !badge ) {
                return;
            }

            if ( categoryCount.count === 0 ) {
                badge.classList.add( 'd-none' );
            } else {
                badge.innerHTML = categoryCount.count.toString();
                badge.classList.remove( 'd-none' );
            }
        } );
    }

    if ( propertyFilterCount === 0 ) {
        document.querySelectorAll<HTMLElement>( '.filtercount[data-type="property"]' ).forEach( ( counter: HTMLElement ) => {
            counter.classList.add( 'd-none' );

            const parentBtn = counter.closest( 'button,a' );

            if ( parentBtn ) {
                parentBtn.classList.remove( 'with-badge' );
            }
        } );
    }

    if ( propertyFilterCount > 0 ) {
        document.querySelectorAll<HTMLElement>( '.filtercount[data-type="property"]' ).forEach( ( counter: HTMLElement ) => {
            counter.innerHTML = propertyFilterCount.toString();
            counter.classList.remove( 'd-none' );

            const parentBtn = counter.closest( 'button,a' );

            if ( parentBtn ) {
                parentBtn.classList.add( 'with-badge' );
            }
        } );
    }
}

function updatePropertyTypes( form: HTMLFormElement ): void {
    const propertyTypeSelect = form.querySelector( '[data-groups]' ) as HTMLSelectElement;

    form.querySelectorAll( '[data-group]' ).forEach( ( group: HTMLElement ) => {
        let disableGroup = true;

        if ( !group.dataset.group ) {
            throw new Error( 'no group found' );
        }

        group.querySelectorAll( 'input' ).forEach( ( input: HTMLInputElement ) => {
            if ( !input.checked ) {
                return;
            }

            disableGroup = false;
            propertyTypeSelect.value = group.dataset.group as string;
            propertyTypeSelect.classList.add( 'has-value' );
        } );

        if ( disableGroup ) {
            group.classList.add( 'ts-checktags--hidden' );
        } else {
            group.classList.remove( 'ts-checktags--hidden' );
        }
    } );
}

export {
    propertySearchFiltersAsURLSearchParams,
    syncPropertySearchForms,
    updatePricesForOfferType,
    updateTagsValidityOnForm,
    getOfferTypeForForm,
    updateResetBtnStates,
    updateFilterCountBadges,
    updatePropertyTypes,
};
