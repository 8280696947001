import { getUser } from './user';
import { parseBool } from '../strconv/boolean';

export interface VisitorInfo {
    at: string;
    city: string;
    country: string;
    isAndroid: boolean;
    isDesktop: boolean;
    isIOS: boolean;
    isMobile: boolean;
    isSmartTV: boolean;
    isTablet: boolean;
    regionName: string;
    xForwardedFor: string;
    origin: string;
}

interface VInfoResponse {
    'at': string;
    'city': string;
    'country': string;
    'is-android': string;
    'is-desktop': string;
    'is-ios': string;
    'is-mobile': string;
    'is-smarttv': string;
    'is-tablet': string;
    'region-name': string;
    'x-forwarded-for': string;
    'origin': string;
}

export async function resolveUsersVisitorInfo(): Promise<VisitorInfo> {
    const user = getUser();

    if ( !user.hasVisitorInfo() ) {
        const response = await fetch( 'https://v-info.thinkspain.com' );

        if ( !response.ok ) {
            throw new Error( 'call for v-info ended with status: ' + response.status );
        }

        const data = await response.json() as VInfoResponse;

        user.setVisitorInfo( {
            at: data.at,
            city: data.city,
            country: data.country,
            isAndroid: parseBool( data[ 'is-android' ] ),
            isDesktop: parseBool( data[ 'is-desktop' ] ),
            isIOS: parseBool( data[ 'is-ios' ] ),
            isMobile: parseBool( data[ 'is-mobile' ] ),
            isSmartTV: parseBool( data[ 'is-smarttv' ] ),
            isTablet: parseBool( data[ 'is-tablet' ] ),
            regionName: data[ 'region-name' ],
            xForwardedFor: data[ 'x-forwarded-for' ],
            origin: data[ 'origin' ],
        } );
    }

    return await user.visitorInfo();
}
