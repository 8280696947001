import * as Sentry from '@sentry/browser';
import Logger from '../logging/logger';
import { getLanguage } from '../user/device-info';
import { getCurrentPagePath } from '../url';
import { parseEscapedJSONString } from '../json/parse';

export const EventNameMailshotSubscribe: string = 'email_subscribe';

export const EventNameMailshotUnSubscribe: string = 'email_unsubscribe';

export const EventNameMailshotReSubscribe: string = 'email_resubscribe';

export type GA4EventParameters = Record<string, string | number | null>;

export interface GA4Event {
    event: string;
    parameters: GA4EventParameters;
}

export default function triggerEvent( eventName: string, parameters: GA4EventParameters = {} ): GA4Event {
    let eventParams: GA4EventParameters = {
        'lang': getLanguage(),
    };

    if ( window.baseAnalyticEventParameters ) {
        eventParams = {
            ...eventParams,
            ...window.baseAnalyticEventParameters,
            ...parameters,
        };
    }

    const elementWithOverrides = document.querySelector<HTMLElement>( '[data-base-analytic-event-parameters-override]' );

    if ( elementWithOverrides && elementWithOverrides.dataset.baseAnalyticEventParametersOverride ) {
        const elementOverrides = parseEscapedJSONString( elementWithOverrides.dataset.baseAnalyticEventParametersOverride || '{}' ) as GA4EventParameters;

        eventParams = {
            ...eventParams,
            ...elementOverrides,
        };
    }

    eventParams = {
        ...eventParams,
        ...parameters,
    };

    eventParams = nullifyPreviouslySetParameters( eventParams );

    const event: GA4Event = {
        event: eventName,
        parameters: eventParams,
    };

    Logger.info( `Analytics event: ${eventName}`, event );

    window.dataLayerPush( event );

    return event;
}

function nullifyPreviouslySetParameters( parameters: GA4EventParameters ): GA4EventParameters {
    let previouslySentParameters: GA4EventParameters = {};

    window.dataLayer.map( ( obj ) => {
        if ( !obj[ 'parameters' ] ) {
            return {};
        }
        return obj[ 'parameters' ];
    } ).forEach( ( parameterObject: GA4EventParameters ) => {
        previouslySentParameters = {
            ...previouslySentParameters,
            ...parameterObject,
        };
    } );

    Object.keys( previouslySentParameters ).forEach( ( key: string ) => {
        previouslySentParameters[ key ] = null;
    } );

    return {
        ...previouslySentParameters,
        ...parameters,
    };
}

type PageView = {
    page?: string;
    contentGroup?: string;
}

export function triggerPageView( overrides: PageView = {} ): void {
    let pageView = {
        event: 'pageview',
        page: getCurrentPagePath(),
        contentGroup: getContentGroup(),
    };

    if ( overrides && Object.keys( overrides ).length > 0 ) {
        pageView = {
            ...pageView,
            ...overrides,
        };
    }

    if ( !pageView[ 'contentGroup' ] ) {
        Sentry.captureException( new Error( 'content group undefined for pageview' ) );
    }

    window.dataLayerPush( pageView );
}

function getContentGroup(): string {
    const contentGroupElement = document.querySelector<HTMLElement>( '#page-popup-container [data-content-group]' );

    if ( contentGroupElement && contentGroupElement.dataset.contentGroup ) {
        return contentGroupElement.dataset.contentGroup;
    }

    return window.contentGroup;
}
