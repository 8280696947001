/**
 * @param {Element} element - the input element to capture the state of
 * @returns an object of the inputs state
 */
function captureInputState( element ) {
    let state = {
        value: element.value,
        dataset: {},
    };

    Object.keys( element.dataset ).forEach( key => {
        state.dataset[ key ] = element.dataset[ key ];
    } );

    return state;
}

/**
 * @param {Element} element - the input element to apply the state to
 * @param {Object} state
 * @returns the element for chaining
 */
function applyInputState( element, state ) {
    element.value = state.value;

    Object.keys( state.dataset || {} ).forEach( ( key ) => {
        element.dataset[ key ] = state.dataset[ key ];
    } );

    const resetContainer = element.closest( '.reseteable' );

    if ( resetContainer ) {
        const reset = resetContainer.querySelector( '.reset-field' );

        if ( reset && state.value.length > 0 ) {
            reset.style.display = '';
        }
    }

    return element;
}

/**
 * @param {Element} input
 * @returns Element - the input passed for chaining
 */
function toggleInputReset( input ) {
    const resetContainer = input.closest( '.reseteable' );

    if ( !resetContainer ) {
        throw new Error( 'failed to find .reseteable' );
    }

    const reset = resetContainer.querySelector( '.reset-field' );

    if ( !reset ) {
        throw new Error( 'failed to find .reset-field' );
    }

    if ( input.value.length > 0 ) {
        reset.style.visibility = '';
    } else {
        reset.style.visibility = 'hidden';
    }

    return input;
}

/**
 * @param {Element} element - the input element to clear the state of
 * @returns the element for chaining
 */
function clearInputState( element, clearValue = true, clearData = true, dataKeys = {} ) {
    if ( clearValue ) {
        element.value = '';
    }

    if ( clearData ) {
        let keys = element.dataset;

        if ( Object.keys( dataKeys ).length > 0 ) {
            keys = dataKeys;
        }

        Object.keys( keys || {} ).forEach( key => {
            element.dataset[ key ] = '';
        } );
    }

    const resetContainer = element.closest( '.reseteable' );

    if ( resetContainer ) {
        const reset = resetContainer.querySelector( '.reset-field' );

        if ( reset ) {
            reset.style.visibility = 'hidden';
        }
    }

    return element;
}

export {
    captureInputState,
    applyInputState,
    clearInputState,
    toggleInputReset,
};
