import { createCookie } from '../cookies';

type EmailSuggestionResult = {
    html: string;
    emailSuggestion: string;
}

export function setupEmailSuggestionFromResult( result: EmailSuggestionResult, form: HTMLFormElement ): void {
    const existingSuggestionElement = form.querySelector( '.didyoumean' );

    if ( existingSuggestionElement ) {
        existingSuggestionElement.remove();
    }

    const emailField = form.querySelector( 'input[type="email"]' ) as HTMLInputElement;

    emailField.classList.add( 'error-message' );

    const formGroup = emailField.closest( '.form-group' );

    if ( formGroup ) {
        formGroup.classList.remove( 'pristine-has-success' );
        formGroup.classList.add( 'pristine-has-danger' );
    }

    emailField.insertAdjacentHTML( 'afterend', result.html );

    const suggestionElement = form.querySelector( '.didyoumean' ) as HTMLElement;

    suggestionElement.classList.add( 'error-message' );

    const btnYes: HTMLAnchorElement | null = suggestionElement.querySelector( '.yes' );

    if ( btnYes ) {
        btnYes.addEventListener( 'click', function( event: Event ): void {
            event.preventDefault();

            emailField.value = result.emailSuggestion;

            emailField.classList.remove( 'error-message' );

            if ( formGroup ) {
                formGroup.classList.remove( 'pristine-has-danger' );
            }

            suggestionElement.remove();
        } );
    }

    const btnNo: HTMLAnchorElement | null = suggestionElement.querySelector( 'a.no' );

    if ( btnNo ) {
        btnNo.addEventListener( 'click', function( event: Event ): void {
            event.preventDefault();

            createCookie( 'email-ok', emailField.value, 14 );

            suggestionElement.remove();

            emailField.classList.remove( 'error-message' );

            if ( formGroup ) {
                formGroup.classList.remove( 'pristine-has-danger' );
            }

            form.dispatchEvent( new CustomEvent( 'twc:submit', { bubbles: true } ) );
        } );
    }
}
