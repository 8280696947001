import * as Sentry from '@sentry/browser';

import {
    canAccessLocalStorage,
    canAccessSessionStorage,
} from './support';

const keyStringError = new TypeError( 'key must be of type string' );
const valueStringError = new TypeError( 'value must be of type string, consider .toString() or JSON.stringify()' );

/**
 * AppStorage is a wrapper around the window.sessionStorage
 * and window.localStorage objects.
 */
class AppStorage {
    localStorageIsSupported: boolean;
    sessionStorageIsSupported: boolean;

    _localStorageFallback: Record<string, string> = {};
    _sessionStorageFallback: Record<string, string> = {};

    constructor() {
        this.localStorageIsSupported = canAccessLocalStorage();
        this.sessionStorageIsSupported = canAccessSessionStorage();

        if ( !this.localStorageIsSupported || !this.sessionStorageIsSupported ) {
            Sentry.captureMessage( 'localStorage/sessionStorage is not supported' );
        }
    }

    /**
     * getLocalItem retrieves a vaue from sessionStorage or the fallback
     * @param {String} key - the key to retrieve the value from
     * @returns String | null - the value from the sessionStorage or the fallback
     */
    getSessionItem( key: string ): string | null {
        if ( typeof key !== 'string' ) {
            throw keyStringError;
        }

        let value: string | null = null;

        if ( this.sessionStorageIsSupported ) {
            value = window.sessionStorage.getItem( key );
        }

        if ( !this.sessionStorageIsSupported ) {
            value = this._sessionStorageFallback[ key ];
        }

        if ( typeof value === 'undefined' ) {
            value = null;
        }

        return value;
    }

    /**
     * setSessionItem set a key/value pair in sessionStorage if supported.
     * Otherwise it will store the values in a fallback object based store.
     * @param {String} key - the key to store the value under
     * @param {String} value - the value to store, this can be any type but will be stringified by .toString()
     * @returns void
     */
    setSessionItem( key: string, value: string ): void {
        if ( typeof key !== 'string' ) {
            throw keyStringError;
        }

        if ( typeof value !== 'string' ) {
            throw valueStringError;
        }

        if ( !this.sessionStorageIsSupported ) {
            this._sessionStorageFallback[ key ] = value;
            return;
        }

        window.sessionStorage.setItem( key, value );
    }

    /**
     * getLocalItem retrieves a vaue from localStorage or the fallback
     * @param {String} key - the key to retrieve the value from
     * @returns String | null - the value from the localStorage or the default value
     */
    getLocalItem( key: string ): string | null {
        if ( typeof key !== 'string' ) {
            throw keyStringError;
        }

        let value: string | null = null;

        if ( this.localStorageIsSupported ) {
            value = window.localStorage.getItem( key );
        }

        if ( !this.localStorageIsSupported ) {
            value = this._localStorageFallback[ key ];
        }

        if ( typeof value === 'undefined' ) {
            value = null;
        }

        return value;
    }

    /**
     * setLocalItem set a key/value pair in localStorage if supported.
     * Otherwise it will store the values in a fallback object based store.
     * @param {String} key - the key to store the value under
     * @param {String} value - the value to store, this can be any type but will be stringified by .toString()
     * @returns void
     */
    setLocalItem( key: string, value: string ): void {
        if ( typeof key !== 'string' ) {
            throw keyStringError;
        }

        if ( typeof value !== 'string' ) {
            throw valueStringError;
        }

        if ( !this.localStorageIsSupported ) {
            this._localStorageFallback[ key ] = value;
            return;
        }

        window.localStorage.setItem( key, value );
    }
}

export default AppStorage;
