import { getDeviceInfo, getLanguage } from '../user/device-info';
import { addRecaptchaField, loadRecaptcha } from '../captcha/google';
import { closeAllUtilityPopups, closeSideNav, openUtilityPopup } from '../popups/utils';
import { getHTML, getUtilityPopupContainer } from '../popups/utility-popups';
import newValidator from '../validation';

function initFeedbackForm() {
    var validator;

    async function handleForm( form ) {
        if ( form.dataset.isProcessing && form.dataset.isProcessing === 'true' ) {
            return;
        }

        var messageContainer = form.querySelector( '.messages' );

        form.dataset.isProcessing = true;

        var formSubmit = form.querySelector( 'button[type=submit]' );
        Ladda.create( formSubmit ).start();

        validator.reset();

        var valid = validator.validate();
        var errors = validator.getErrors();

        if ( errors.length > 0 ) {
            var eventLabel = 'errors:' + errors.length;

            for ( var i = 0; i < errors.length; i++ ) {
                var name = errors[ i ].input.name;
                var value = errors[ i ].input.value;
                eventLabel += ';' + name + '=' + value;
            }

            window.dataLayerPush( {
                'event': 'form-submit',
                'action': form.dataset.formName + '-errors',
                'label': eventLabel,
                'value': 1,
            } );
        }

        if ( !valid ) {
            form.dataset.isProcessing = false;
            Ladda.stopAll();
            return;
        }

        messageContainer.innerHTML = '';

        var sendRequest = function sendRequest() {
            var formData = new FormData( form );

            formData.append( 'deviceinfo', JSON.stringify( getDeviceInfo() ) );

            formData.append( 'routerstate', JSON.stringify( {
                current: window.router.currentState,
                previous: window.router.previousState,
                initial: window.router.initialState,
            } ) );

            var url = new URL( window.location.origin + form.getAttribute( 'action' ) );

            http( url, {
                method: 'POST',
                body: formData,
            } )
                .then( function( response ) {
                    return response.json();
                } )
                .then( function( response ) {
                    form.dataset.isProcessing = false;
                    Ladda.stopAll();

                    if ( response.success ) {
                        document.body.insertAdjacentHTML( 'beforeend', response.content );

                        var confirmation = document.querySelector( '.feedback-enquiry-confirmation' );

                        var confirmationBtn = confirmation.querySelector( '.feedback-enquiry-confirmation button' );

                        confirmationBtn.addEventListener( 'click', function( ) {
                            confirmation.remove();
                            document.body.classList.remove( 'popup-feedback-open' );
                        } );

                        if ( form.dataset.formName ) {
                            window.dataLayerPush( {
                                'event': 'form-submit',
                                'action': form.dataset.formName,
                                'label': 'lang:' + getLanguage(),
                                'value': 1,
                            } );
                        }
                    } else if ( response.errors && response.errors.form ) {
                        messageContainer.insertAdjacentHTML( 'beforeend', '<div class="alert alert-danger">' + response.errors.form + '</div>' );
                        // scrolling to the message does not make sense on feedback form
                    }
                } );
        };

        var captchaAction = form.dataset.captchaAction;

        const token = await loadRecaptcha( captchaAction );

        addRecaptchaField( form, token );
        sendRequest();
    }

    async function onOpen( event ) {
        let feedbackPopup = document.querySelector( '.popup__container--give-feedback' );

        if ( !feedbackPopup ) {
            const popupContainer = getUtilityPopupContainer();
            feedbackPopup = await getHTML( 'feedback-popup' );
            popupContainer.appendChild( feedbackPopup );
        }

        if ( !feedbackPopup ) {
            return;
        }

        const closeFeedbackButton = feedbackPopup.querySelector( '.popup__close' );

        if ( closeFeedbackButton ) {
            closeFeedbackButton.addEventListener( 'click', closeAllUtilityPopups );
        }

        const form = feedbackPopup.querySelector( 'form' );

        if ( !form.dataset.initialised ) {
            validator = newValidator( form );

            form.dataset.initialised = true;

            form.addEventListener( 'submit', function( event ) {
                event.preventDefault();

                handleForm( form );
            } );
        }

        await closeSideNav();

        closeAllUtilityPopups( false );

        openUtilityPopup( feedbackPopup );
    }

    const init = function() {
        document.querySelectorAll( '.button-feedback' ).forEach( btn => {
            if ( btn.dataset.initialised && btn.dataset.initialised === 'true' ) {
                return;
            }
            btn.addEventListener( 'click', onOpen );
            btn.dataset.initialised = 'true';
        } );
    };

    document.addEventListener( 'twc:newcontentloaded', init );

    init();
}

export default initFeedbackForm;
