import Typeahead, { TypeaheadOptions } from './typeahead';
import SearchHandler from './search-handler';
import { Suggestion } from './suggestion-types/common';
import { removeDiacritics } from '../strings/format';

class LocationSearch {
    _typeahead: Typeahead;

    constructor( container: HTMLElement ) {
        const typeaheadInput = container.querySelector( 'input[type=text]' ) as HTMLInputElement;

        if ( !typeaheadInput ) {
            throw new Error( 'cannot create location search without typeahead input element' );
        }

        const limit = 12;

        const namespace = container.dataset.typeahead as string;

        const typeaheadOptions: TypeaheadOptions = {
            limit: limit,
            inputHandlers: [],
        };

        if ( namespace === 'categories' ) {
            typeaheadOptions.filter = ( suggestion: Suggestion, input: string ) => {
                return removeDiacritics( suggestion.label.toLowerCase() ).indexOf( removeDiacritics( input.toLowerCase() ) ) > -1;
            };
        }

        if ( typeaheadInput.dataset.detectIdInput && typeaheadInput.dataset.detectIdInput === 'true' && typeaheadInput.dataset.detectIdEndpoint ) {
            typeaheadOptions.inputHandlers.push( new SearchHandler( {
                limit: limit,
                url: new URL( window.location.origin + typeaheadInput.dataset.detectIdEndpoint ),
                test: ( q: string ): boolean => {
                    // Property IDs are never padded within leading zeros
                    // The current biggest is 6,810,294, lets support up to 999,999,999
                    return /[1-9]{1}[0-9]{5,8}/.test( q );
                },
                transform: ( query: string ): string => {
                    return parseInt( query.replace( /\D/g, '' ) ).toString();
                },
            } ) );
        }

        if ( container.dataset.typeaheadDefaultSource ) {
            const options = {
                limit: limit,
                url: new URL( window.location.origin + container.dataset.typeaheadDefaultSource ),
            };

            typeaheadOptions.inputHandlers.push( new SearchHandler( options ) );
        }

        this._typeahead = new Typeahead( typeaheadInput, container, typeaheadOptions );
    }
}

export default async function initTypeaheads() {
    document.querySelectorAll<HTMLElement>( '[data-typeahead]' ).forEach( ( element: HTMLElement ) => new LocationSearch( element ) );

    document.querySelectorAll<HTMLInputElement>( 'input[name=locationFreeText]' ).forEach( ( el: HTMLInputElement ) => {
        el.addEventListener( 'twc:typeahead-deselect', () => {
            const dispatchChange = false;

            const parentForm = el.closest( 'form' ) as HTMLFormElement;
            const typeaheadElements = parentForm.querySelectorAll<HTMLInputElement>( '[name=locationFreeText]' );
            const locationIdElements = parentForm.querySelectorAll<HTMLInputElement>( '[name=locationId]' );
            const radiusContainers = document.querySelectorAll<HTMLElement>( '.searchpopup__location-radius' );
            radiusContainers.forEach( ( container: HTMLElement ) => container.classList.add( 'd-none' ) );
            const radiusInputs = parentForm.querySelectorAll<HTMLSelectElement>( 'select[name=radius]' );

            radiusInputs.forEach( ( radius: HTMLSelectElement ) => {
                radius.value = '';
                radius.disabled = true;
            } );

            locationIdElements.forEach( ( input: HTMLInputElement ) => {
                input.value = '';
                input.dataset.id = '0';
                input.dataset.type = '';
                input.dataset.isIsland = '';
                input.dataset.isGeocoded = '';
                input.dataset.longitude = '';
                input.dataset.latitude = '';
                input.dataset.location = '';

                if ( dispatchChange ) {
                    input.dispatchEvent( new Event( 'change' ) );
                }
            } );

            typeaheadElements.forEach( ( input: HTMLInputElement ) => {
                input.dataset.tsGeo = 'false';
            } );
        } );
    } );
}
