import * as Sentry from '@sentry/browser';
import type { HttpMiddleware, RequestObj } from '../models';
import type { SentryError } from '../../logging/sentry';
import Logger from '../../logging/logger';

export default function( next: HttpMiddleware ) {
    return async function( url: string, request: RequestObj ): Promise<Response> {
        try {
            const response: Response = await next( url, request );

            if ( process.env.HTTP_LOGGING === 'true' ) {
                Logger.debug( '[http] skipping Sentry log', response );
            }

            return response;
        } catch ( err: any ) {
            if ( err instanceof DOMException && err.name === 'AbortError' ) {
                throw err;
            }

            if ( process.env.HTTP_LOGGING === 'true' ) {
                Logger.debug( '[http] logging unhandled error to Sentry' );
            }

            const error = new Error( `request to "${url}" resulted in error: ${err.toString()}` ) as SentryError;
            error.name = err.name;
            error.stack = err.stack;

            Sentry.captureException( error );

            // Ensure the error isn't logged to Sentry twice
            error.sentryIgnored = true;

            throw error;
        }
    };
}
