export function calculateElementHeaderOffset( element: HTMLElement ): number {
    const header = document.querySelector( '.primaryheader .header-inner' ) as HTMLElement;
    const headerRect: DOMRect = header.getBoundingClientRect();
    const inputRect: DOMRect = element.getBoundingClientRect();

    const diff = Math.ceil( inputRect.top - headerRect.bottom );

    if ( diff === 0 ) {
        return 0;
    }

    const y = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top = y;

    if ( diff > 0 ) {
        top = y + diff;
    }

    if ( diff < 0 ) {
        top = y - ( diff * -1 );
    }

    // add padding between elements
    top = top - 10;

    return Math.ceil( top );
}
