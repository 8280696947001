export interface State {
    title: string;
    description: string;
    url: string;
    fragment: string;
    scrollPosition: number;
    isRoutedPopup: boolean;
    isDirect: boolean;
    onlyUpdateState: boolean;
    hasFragment: boolean;
    manualPopstate: boolean,
    meta: any;
    events: StateEvents;
    container?: HTMLElement;
    previous?: State;
    additionalParams?: string;
}

interface StateEvents {
    open: Array<string>;
    close: Array<string>;
    dataLayer: Array<string>;
}

export function newState(): State {
    return {
        title: '',
        description: '',
        url: '',
        fragment: '',
        scrollPosition: 0,
        isRoutedPopup: true,
        isDirect: false,
        onlyUpdateState: false,
        hasFragment: false,
        manualPopstate: false,
        meta: {},
        events: {
            open: [],
            close: [],
            dataLayer: [],
        },
    };
}

export function getState(): State {
    return JSON.parse( JSON.stringify( history.state ) );
}
