/**
 * @param {String} url - the URL/relative path of the stylesheet to load
 */
function loadCss( url: string ): void {
    const link = document.createElement( 'link' );
    link.rel = 'stylesheet';
    link.href = url;
    document.head.appendChild( link );
}

export {
    loadCss,
};
