import Awesomplete from 'awesomplete';

// eslint-disable-next-line @typescript-eslint/naming-convention
const CustomAwesomplete = Awesomplete;

CustomAwesomplete.prototype.open = async function() {
    this.ul.classList.add( 'visible' );
    this.isOpened = true;
};

CustomAwesomplete.prototype.close = function() {
    if ( !this.opened ) {
        return;
    }

    this.ul.classList.remove( 'visible' );
    this.isOpened = false;
    this.index = -1;
};

export default CustomAwesomplete;
