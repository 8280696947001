import Logger from '../../logging/logger';
import type { HttpMiddleware, RequestObj } from '../models';

type UserData = {
    id: number;
    email: string;
    emailHash: string;
    verified: string;
}

export default function( next: HttpMiddleware ) {
    return async function( uri: string, request: RequestObj ): Promise<Response> {
        const response: Response = await next( uri, request );

        if ( process.env.HTTP_LOGGING === 'true' ) {
            Logger.debug( '[http] identifying response', response );
        }

        if ( !window.twc ) {
            return response;
        }

        const userDataJSON = response.headers.get( 'X-Twc-Userdata' );

        if ( userDataJSON ) {
            const userData = JSON.parse( userDataJSON ) as UserData;

            window.twc.identify( userData );

            const dataLayerContainsHashedUserID = window.dataLayer.some( x => !!x[ 'user_id' ] );
            const dataLayerContainsHashedUserEmail = window.dataLayer.some( x => !!x[ 'user_email' ] );

            const userDataToPush = {};

            if ( !dataLayerContainsHashedUserID ) {
                userDataToPush[ 'user_id' ] = userData.emailHash;
            }

            if ( !dataLayerContainsHashedUserEmail ) {
                userDataToPush[ 'user_email' ] = userData.emailHash;
            }

            if ( Object.keys( userDataToPush ).length > 0 ) {
                window.dataLayerPush( userDataToPush );
            }
        }

        return response;
    };
}
