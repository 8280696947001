import Logger from './logger';

export function initGlobalErrorLogger(): void {
    window.addEventListener( 'error', ( event: ErrorEvent ): void => {
        Logger.store( 'error', event.message, event.error );
    } );
}

export function getLoggedUnhandledErrors(): Array<Error> {
    const sessionErrors: Array<Error> = window.appLogs.filter( ( entry: Array<any> ) => {
        if ( entry.length === 2 && entry[ 1 ] instanceof Error ) {
            return true;
        }
        return false;
    } ).map( ( entry: Array<any> ) => {
        return entry[ 1 ] as Error;
    } );

    return sessionErrors;
}
