function getDeviceInfo() {
    return {
        availHeight: window.screen.availHeight,
        availWidth: window.screen.availWidth,
        height: window.screen.height,
        width: window.screen.width,
        devicePixelRatio: window.devicePixelRatio,
    };
}

function getLocale() {
    const locale = document.documentElement.dataset.locale;

    if ( locale ) {
        return locale;
    }

    return getLanguage();
}

function getLanguage(): string {
    return document.documentElement.getAttribute( 'lang' ) as string;
}

function isTouchDevice(): boolean {
    if ( 'ontouchstart' in window ) {
        return true;
    }
    if ( window.navigator.maxTouchPoints > 0 ) {
        return true;
    }
    if ( window.navigator.msMaxTouchPoints && window.navigator.msMaxTouchPoints > 0 ) {
        return true;
    }
    return false;
}

function isFullScreen(): boolean {
    if ( document.fullscreenElement ) {
        return true;
    }
    if ( document.mozFullScreen ) {
        return true;
    }
    if ( document.webkitIsFullScreen ) {
        return true;
    }
    return false;
}

export {
    getDeviceInfo,
    getLocale,
    getLanguage,
    isTouchDevice,
    isFullScreen,
};
