import Typeahead, { AwesompleteEvent } from '../typeahead';
import { createBaseLI } from './common';

class SectionSuggestion {
    label: string;
    value: string;
    icon: string;
    suggestionType = 'section';
    _item?: HTMLLIElement;

    select( ctx: Typeahead, event: AwesompleteEvent ): boolean {
        return false;
    }

    selected( ctx: Typeahead ): void {
        return;
    }

    item( highlightElement: HTMLElement, ref: string, index: number ): HTMLLIElement {
        if ( !this._item ) {
            this._item = createBaseLI( ref, index, this );
            this._item.appendChild( highlightElement );
            this._item.classList.add( 'typeahead__option--no-select', 'typeahead__section' );
        }
        return this._item;
    }
}

export default function newSectionSuggestion( obj: any ): SectionSuggestion {
    const s = new SectionSuggestion();
    s.label = obj.name || obj.label || '';
    s.value = obj.value || obj.id || '';
    return s;
}
