import Logger from '../logging/logger';
import { validateURLString } from '../url/validate';
import type { RequestObj } from './models';

export default function( base: string ) {
    const baseURL = new URL( base );

    return async function( uri: URL | string, request: RequestObj ): Promise<Response> {
        let url: URL | undefined;

        try {
            if ( typeof uri === 'string' && uri.startsWith( 'http' ) ) {
                url = new URL( uri );
            }

            if ( typeof uri === 'string' && uri.startsWith( '/' ) ) {
                url = new URL( uri, baseURL );
            }

            if ( uri instanceof URL ) {
                url = uri;
            }

            if ( !url ) {
                throw new ReferenceError( 'url is undefined' );
            }
        } catch ( err ) {
            throw new Error( `failed constructing URL with path ${uri}, error: ${err.message}` );
        }

        const err = validateURLString( url.toString() );

        if ( err !== null ) {
            throw err;
        }

        let r: RequestObj = {
            method: 'GET',
            headers: {},
        };

        r = Object.assign( {}, r, request );

        if ( url.origin === window.location.origin ) {
            r.headers[ 'X-REQUESTED-WITH' ] = 'XMLHttpRequest';
        }

        if ( process.env.HTTP_LOGGING === 'true' ) {
            Logger.debug( `[http] starting request: ${r.method} ${url.toString()}` );
        }

        return await fetch( url, r ) as Response;
    };
}
