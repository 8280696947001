/**
 * parseBool returns a type Boolean for a String represents a Boolean
 * @param {String} str - the string representing a Boolean
 */
function parseBool( str: string ): boolean {
    if ( str && str.toLowerCase() === 'true' ) {
        return true;
    }
    return false;
}

export {
    parseBool,
};
