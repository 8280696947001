import { validateURLString } from '../url/validate';

/**
 * @param {String} url - the URL to navigate to
 * @returns void
 */
function navigateTo( url: string ): void {
    if ( url.length === 0 ) {
        throw new Error( 'invalid argument passed to navigateTo function, url: ' + url );
    }

    const err = validateURLString( url );

    if ( err !== null ) {
        throw err;
    }

    window.location.href = url;
}

export {
    navigateTo,
};
