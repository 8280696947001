import { closeExpandedDropdowns } from '../dropdown';
import requestIdleCallback from '../shims/requestIdleCallback';
import { yieldToMainThread } from '../yield';
import { getUtilityPopupContainer } from './utility-popups';

function openUtilityPopup( popupElement: Element ): void {
    const container = getUtilityPopupContainer();
    popupElement.classList.add( 'popup__container--open' );
    container.classList.add( 'popup__wrapper--open' );
    document.body.classList.add( 'utility-popup-open' );
}

function closeAllUtilityPopups( closeContainer = true ): void {
    const container = getUtilityPopupContainer();

    if ( closeContainer ) {
        container.classList.remove( 'popup__wrapper--open' );
        document.body.classList.remove( 'utility-popup-open' );
    }

    container.querySelectorAll( '.popup__container--open' ).forEach( ( popup: HTMLElement ) => {
        if ( popup.dataset.onClose && popup.dataset.onClose === 'remove' ) {
            popup.remove();
        } else {
            popup.classList.remove( 'popup__container--open' );
        }

        popup.dispatchEvent( new CustomEvent( 'twc:popup:close-utility-popup' ) );
    } );
}

async function openSideNav(): Promise<void> {
    document.body.classList.add( 'side-nav-open' );

    await yieldToMainThread();

    requestIdleCallback( () => {
        document.body.style.overflowY = 'hidden';
    } );

    requestIdleCallback( () => {
        document.dispatchEvent( new CustomEvent( 'twc:collapse:collapse-all', { bubbles: true } ) );
        closeExpandedDropdowns();
    } );
}

async function closeSideNav(): Promise<void> {
    document.body.classList.remove( 'side-nav-open' );

    await yieldToMainThread();

    requestIdleCallback( () => {
        document.body.style.overflowY = '';
    } );

    requestIdleCallback( () => {
        document.dispatchEvent( new CustomEvent( 'twc:collapse:collapse-all', { bubbles: true } ) );
    } );
}

function closeAccountMenu(): void {
    const accountMenu = document.querySelector( '.my-account-wrap' ) as HTMLElement;
    const accountBtn = document.querySelector( '.account' );

    if ( !accountMenu || !accountBtn || !document.body.classList.contains( 'my-account-open' ) ) {
        return;
    }

    document.body.classList.remove( 'my-account-open' );

    accountMenu.style.display = 'none';

    requestIdleCallback( () => {
        document.body.style.overflowY = '';
        accountBtn.classList.remove( 'open' );
    } );
}

function openAccountMenu(): void {
    const accountMenu = document.querySelector( '.my-account-wrap' ) as HTMLElement;
    const accountBtn = document.querySelector( '.account' );

    if ( !accountMenu || !accountBtn ) {
        throw new Error( 'failed to find account menu elements' );
    }

    document.body.classList.add( 'my-account-open' );

    accountMenu.style.display = 'block';

    requestIdleCallback( () => {
        document.body.style.overflowY = 'hidden';
        accountBtn.classList.add( 'open' );
    } );
}

function anyPopupOpen(): boolean {
    return pagePopupOpen() || utilityPopupOpen();
}

function pagePopupOpen(): boolean {
    return document.body.classList.contains( 'page-popup-open' );
}

function utilityPopupOpen(): boolean {
    return document.body.classList.contains( 'utility-popup-open' );
}

function onScrollWithTimeout( callback: EventListener ): EventListener {
    let scrollingTimeout: number | null = null;

    return function( event: Event ): void {
        if ( scrollingTimeout !== null ) {
            window.clearTimeout( scrollingTimeout );
        }

        scrollingTimeout = window.setTimeout( function() {
            callback( event );
            scrollingTimeout = null;
        }, 250 );
    };
}

export {
    closeAllUtilityPopups,
    openUtilityPopup,
    closeSideNav,
    openSideNav,
    anyPopupOpen,
    pagePopupOpen,
    utilityPopupOpen,
    onScrollWithTimeout,
    openAccountMenu,
    closeAccountMenu,
};
