function canAccessSessionStorage() {
    try {
        window.sessionStorage.setItem( 'session-storage-support-test', 'true' );
        window.sessionStorage.getItem( 'session-storage-support-test' );
    } catch ( err ) {
        return false;
    }
    return true;
}

function canAccessLocalStorage() {
    try {
        window.localStorage.setItem( 'local-storage-support-test', 'true' );
        window.localStorage.getItem( 'local-storage-support-test' );
    } catch ( err ) {
        return false;
    }
    return true;
}

export {
    canAccessLocalStorage,
    canAccessSessionStorage,
};
