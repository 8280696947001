function parseCoordinateString( str: string ): Array<Array<number>> {
    const split = str.split( ',' );
    const shape: Array<Array<number>> = [];

    for ( let i = 0; i < split.length - 1; i++ ) {
        if ( i % 2 !== 0 ) {
            continue;
        }

        shape.push( [
            parseFloat( split[ i ] ),
            parseFloat( split[ i + 1 ] ),
        ] );
    }

    return shape;
}

export {
    parseCoordinateString,
};
