function mouseOut( event ) {
    const pos = {
        x: event.clientX,
        y: event.clientY,
    };

    const fromElement = event.relatedTarget || event.toElement;

    if ( pos.y > 0 || fromElement ) {
        return;
    }

    if ( window.exitIntentTimout ) {
        window.clearTimeout( window.exitIntentTimout );
    }

    window.exitIntentTimout = window.setTimeout( function() {
        document.dispatchEvent( new CustomEvent( 'twc:exit-intent', {
            bubbles: true,
            position: pos,
        } ) );
    }, 0 );
}

function exitIntent() {
    document.addEventListener( 'mouseleave', mouseOut, { passive: true } );
}

export default exitIntent;
