/**
 * isMapMode checks whether the search is for the map
 * @returns Boolean
 */
function isMapMode(): boolean {
    return document.documentElement.classList.contains( 'mapsearch' );
}

export {
    isMapMode,
};
