const PollingTimout: number = 2.5;

/**
 * trackInactivity invokes a function once the user is inactive for the limit specified
 * @param {number} limit - the inactivity limit in seconds
 * @param {function} callback - a function to invoke when the limit is reached
 */
function trackInactivity( limit: number, callback?: () => void ) {
    if ( window.idleTimer ) {
        window.clearInterval( window.idleTimer );
    }

    let idleSecondsCounter = 0;

    const passive = {
        passive: true,
    };

    const activityEvents: Array<string> = [
        'click',
        'mousemove',
        'scroll',
        'keydown',
        'touchstart',
    ];

    const resetCounter = (): void => {
        idleSecondsCounter = 0;
    };

    activityEvents.forEach( eventName => {
        document.addEventListener( eventName, resetCounter, passive );
    } );

    const checkTimeout = (): void => {
        idleSecondsCounter += PollingTimout;

        if ( idleSecondsCounter >= limit ) {
            window.clearInterval( window.idleTimer );

            if ( callback ) {
                callback();
            }
        }
    };

    window.idleTimer = window.setInterval( checkTimeout, PollingTimout * 1000 );
}

export {
    trackInactivity,
};
