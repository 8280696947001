export type PropertyAlertNotificationFrequency = 'Never' | 'Daily' | 'Weekly';

export const NotificationFrequencyNever = 'Never';

export const NotificationFrequencyDaily = 'Daily';

export const NotificationFrequencyWeekly = 'Weekly';

export function getEmailAlertFrequency( form: HTMLFormElement ): PropertyAlertNotificationFrequency | null {
    const checkedOption = Array.from( form.querySelectorAll<HTMLInputElement>( 'input[name="frequency"]' ) ).find( ( input: HTMLInputElement ) => {
        return input.checked;
    } );

    if ( !checkedOption ) {
        return null;
    }

    return checkedOption.value as PropertyAlertNotificationFrequency;
}
