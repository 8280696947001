/**
 * parseEscapedJSONString returns a parsed JSON string that has been escaped
 * @param {String} jsonString - escaped JSON string
 */
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function parseEscapedJSONString<T>( jsonString: string ): T | any {
    if ( typeof jsonString !== 'string' ) {
        throw new TypeError( 'cannot parse objects not of type String' );
    }

    jsonString = jsonString.replace( /\\[\\]{0,}"/g, '"' );

    jsonString = jsonString.replace( /\\\\u/g, '\\u' );

    return JSON.parse( jsonString );
}

export {
    parseEscapedJSONString,
};
