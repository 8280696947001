function upsertHiddenInput( form: HTMLFormElement, name: string, value: string ) {
    const existing = form.querySelector<HTMLInputElement>( '[name=' + name + ']' );

    if ( existing ) {
        existing.value = value;
        return existing;
    }

    const input = document.createElement( 'input' );
    input.setAttribute( 'type', 'hidden' );
    input.setAttribute( 'name', name );
    input.setAttribute( 'value', value );

    form.appendChild( input );

    return input;
}

export {
    upsertHiddenInput,
};
