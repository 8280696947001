import { navigateTo } from '../http/redirect';

function closeEnquiryConfirmation() {
    if ( window.router.initialState.isRoutedPopup ) {
        navigateTo( document.body.dataset.baseUrl || '/' );
        return;
    }
    window.router.Rewind();
}

/**
 * @description onDirectoryEnquiryClose handles the event triggered for closing the directory enquiry confirmation
 * @param {MouseEvent} event - the native MouseEvent event triggered
 */
function onDirectoryEnquiryClose() {
    closeEnquiryConfirmation();
}

export {
    onDirectoryEnquiryClose,
};
