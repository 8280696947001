const OnlyStandardCharacters: RegExp = /^[a-zA-Z0-9'/|"@._+-]+$/;
const ValidEmailDomain: RegExp = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

export function validateEmailAddress( email: string ): boolean {
    email = email.trim();

    if ( !email ) {
        return false;
    }

    const parts = email.split( '@' );

    if ( parts.length !== 2 ) {
        return false;
    }

    if ( !ValidEmailDomain.test( parts[ 1 ] ) ) {
        return false;
    }

    if ( !OnlyStandardCharacters.test( email ) ) {
        return false;
    }

    return true;
}
