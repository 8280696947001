const brokenSegments: string[] = [ 'undefined', 'null', 'NaN' ];

function validateURLString( urlString: string ): Error | null {
    let err: Error | null = null;

    if ( !urlString || typeof urlString !== 'string' ) {
        return new Error( 'invalid argument passed to validateURLString function, url: ' + urlString );
    }

    for ( let i = 0; i < brokenSegments.length; i++ ) {
        const segment: string = brokenSegments[ i ];
        if ( urlString.includes( segment ) ) {
            err = new Error( 'suspected broken URL path found including "' + segment + '", url: ' + urlString );
            break;
        }
    }

    if ( err !== null ) {
        return err;
    }

    return null;
}

export {
    validateURLString,
};
