import getIcon from '../svgs';
import { applyInputState, captureInputState, clearInputState } from '../forms/input';
import { yieldToMainThread } from '../yield';

type ResetableTypeaheadInputOptions = {
    typeaheadInput: HTMLInputElement;
    valueInput: HTMLInputElement;
    stateful: boolean;
}

class ResetableTypeaheadInput {
    _typeaheadInput: HTMLInputElement;
    _valueInput: HTMLInputElement;
    _container: HTMLDivElement;
    _btn: HTMLButtonElement;

    _blockBlur = false;
    _blockChange = false;

    _stateful: boolean;
    _state: any;

    _debounce: ( f: () => any ) => void;

    constructor( options: ResetableTypeaheadInputOptions ) {
        this._stateful = options.stateful;
        this._typeaheadInput = options.typeaheadInput;
        this._valueInput = options.valueInput;

        this._btn = document.createElement( 'button' );
        this._btn.type = 'button';
        this._btn.className = 'reset-field bring-to-front';
        this._btn.innerHTML = getIcon( 'closeX', 'grey-d2' );

        this._container = document.createElement( 'div' );
        this._container.className = 'reseteable';
        this._container.appendChild( this._btn );

        if ( !this._typeaheadInput.parentNode ) {
            throw new Error( 'no parent element found' );
        }

        this._debounce = function( func: () => any ) {
            window.setTimeout( func, 200 );
        };

        this._typeaheadInput.parentNode.insertBefore( this._container, this._typeaheadInput );
        this._container.appendChild( this._typeaheadInput );

        this._btn.addEventListener( 'click', this._onClick.bind( this ) );

        this._typeaheadInput.addEventListener( 'input', this._toggleVisibility.bind( this ) );
        this._typeaheadInput.addEventListener( 'change', this._toggleVisibility.bind( this ) );
        this._typeaheadInput.addEventListener( 'awesomplete-selectcomplete', this._toggleVisibility.bind( this ) );

        if ( this._stateful ) {
            this._typeaheadInput.addEventListener( 'change', this._onChange.bind( this ) );
            this._typeaheadInput.addEventListener( 'blur', this._onBlur.bind( this ) );
            this._typeaheadInput.addEventListener( 'keydown', this._onKeydown.bind( this ) );
        }

        this._toggleVisibility();
    }

    async _onClick( event: Event ): Promise<void> {
        this._blockChange = true;

        if ( this._stateful ) {
            const value = this._typeaheadInput.value;

            this._typeaheadInput.dataset.previousValue = value;

            if ( this._valueInput.value.length > 0 ) {
                this._state = this._captureState();
            }
        }

        this._typeaheadInput.value = '';
        this._valueInput.value = '';
        this._btn.style.visibility = 'hidden';

        this._typeaheadInput.dispatchEvent( new CustomEvent( 'twc:typeahead-deselect', { bubbles: true } ) );

        await yieldToMainThread();

        this._typeaheadInput.focus();
    }

    _onBlur( event: Event ): void {
        this._debounce( () => {
            if ( this._blockBlur ) {
                this._blockBlur = false;
                return;
            }

            const noPreviousValue = typeof this._typeaheadInput.dataset.previousValue === 'undefined';
            const noValue = this._valueInput.value.length === 0;

            if ( noPreviousValue && noValue ) {
                this._typeaheadInput.value = '';
                this._toggleVisibility();
                return;
            }

            if ( noPreviousValue ) {
                return;
            }

            if ( document.activeElement && document.activeElement.getAttribute( 'name' ) === this._typeaheadInput.name ) {
                return;
            }

            if ( noValue || this._typeaheadInput.value !== this._typeaheadInput.dataset.previousValue ) {
                this._restoreState();
                this._toggleVisibility();
            }
        } );
    }

    _onChange( event: Event ): void {
        if ( this._valueInput.value.length > 0 ) {
            this._typeaheadInput.dataset.previousValue = this._typeaheadInput.value;
            this._captureState();
        }
    }

    _onKeydown( event: Event ): void {
        if ( this._valueInput.value.length > 0 ) {
            this._typeaheadInput.dataset.previousValue = this._typeaheadInput.value;
            this._state = this._captureState();
        }
    }

    _toggleVisibility() {
        if ( this._typeaheadInput.value && this._typeaheadInput.value.length > 0 ) {
            this._btn.style.visibility = '';
        } else {
            this._btn.style.visibility = 'hidden';
        }
    }

    _captureState(): any {
        clearInputState( this._valueInput );
        return captureInputState( this._valueInput );
    }

    _restoreState(): void {
        if ( !this._typeaheadInput.dataset.previousValue ) {
            this._typeaheadInput.value = '';
            return;
        }

        this._typeaheadInput.value = this._typeaheadInput.dataset.previousValue;

        if ( this._state ) {
            applyInputState( this._valueInput, this._state );
        }

        this._btn.style.visibility = '';
    }

    block( block: boolean ): void {
        this._blockBlur = block;
    }

    setState( state: any ): void {
        this._state = state;
    }
}

export default ResetableTypeaheadInput;
