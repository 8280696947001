import Typeahead, { AwesompleteEvent } from '../typeahead';

export interface Suggestion {
    label: string;
    value: string;
    suggestionType: string;
    icon: string;
    allowedCountries?: Array<string>;
    select: ( ctx: Typeahead, event: AwesompleteEvent ) => boolean;
    selected: ( ctx: Typeahead ) => void;
    item: ( highlightElement: HTMLElement, ref: string, index: number ) => HTMLLIElement;
}

const icons = {
    'spanish-flag': {
        width: 18,
        height: 18,
    },
    'target': {
        width: 12,
        height: 12,
    },
    'pin': {
        width: 12,
        height: 18,
    },
    'polygon': {
        width: 14,
        height: 18,
    },
};

export function createBaseLI( ref: string, index: number, item: Suggestion ): HTMLLIElement {
    const element =  document.createElement( 'li' );
    element.id = 'awesomplete_list_' + ref + '_item_' + index;
    element.className = 'typeahead__option';
    element.role = 'option';
    element.ariaSelected = 'false';
    element.dataset.id = item.value;

    if ( item.icon ) {
        const img = document.createElement( 'img' );
        img.src = `${window.assetsPrefix}/images/icons/${item.icon}.svg`;
        img.width = icons[ item.icon ].width;
        img.height = icons[ item.icon ].height;
        img.loading = 'lazy';
        element.appendChild( img );
        element.classList.add( 'typeahead__option--with-icon' );
    }

    return element;
}
