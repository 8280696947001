export function isVisible( element: HTMLElement ): boolean {
    if ( !element.offsetParent ) {
        return false;
    }

    const popupParent = element.closest( '.pagepopup__wrapper, .popup__wrapper' );

    if ( popupParent ) {
        if ( !/--open/.test( popupParent.className ) ) {
            return false;
        }

        const style = getComputedStyle( popupParent );

        if ( style[ 'visibility' ] === 'hidden' ) {
            return false;
        }

        if ( style[ 'contentVisibility' ] === 'hidden' ) {
            return false;
        }
    }

    return true;
}
