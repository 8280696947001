import { onDYSButtonClick } from '../../maps/components/draw-your-search/event-handler';
import Typeahead, { AwesompleteEvent } from '../typeahead';
import { createBaseLI } from './common';

class DrawYourSearchSuggestion {
    label: string;
    value: string;
    urls: Record<string, string>;
    icon: string;
    suggestionType = 'draw-your-search';
    _item?: HTMLLIElement;

    select( ctx: Typeahead, event: AwesompleteEvent ): boolean {
        ctx._awesomeplete.close();

        onDYSButtonClick( ctx._form, ctx._input );

        return false;
    }

    selected( ctx: Typeahead ): void {
        return;
    }

    item( highlightElement: HTMLElement, ref: string, index: number ): HTMLLIElement {
        if ( !this._item ) {
            this._item = createBaseLI( ref, index, this );
            this._item.dataset.id = 'draw-your-search';
            this._item.appendChild( highlightElement );
        }
        return this._item;
    }
}

export default function newDrawYourSearchSuggestion( obj: any ): DrawYourSearchSuggestion {
    const s = new DrawYourSearchSuggestion();
    s.value = obj.value || obj.id || '';
    s.label = obj.name || obj.label || '';
    s.urls = obj.urls || '';
    s.icon = obj.icon || '';
    return s;
}

