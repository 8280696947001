import { navigateTo } from '../../http/redirect';
import Typeahead, { AwesompleteEvent } from '../typeahead';
import { createBaseLI } from './common';

class NavigatableSuggestion {
    label: string;
    value: string;
    url: string;
    icon: string;
    suggestionType = 'link';
    _item?: HTMLLIElement;

    select( ctx: Typeahead, event: AwesompleteEvent ): boolean {
        return true;
    }

    selected( ctx: Typeahead ): void {
        ctx.startLoading();
        navigateTo( this.url );
    }

    item( highlightElement: HTMLElement, ref: string, index: number ): HTMLLIElement {
        if ( !this._item ) {
            this._item = createBaseLI( ref, index, this );
            this._item.appendChild( highlightElement );
        }
        return this._item;
    }
}

export default function newNavigatableSuggestion( obj: any ): NavigatableSuggestion {
    const s = new NavigatableSuggestion();
    s.value = obj.value || obj.id || '';
    s.label = obj.name || obj.label || '';
    s.url = obj.url || '';
    s.icon = obj.icon || '';
    return s;
}
