import GlobalContext from '../bootstrap';
import initFeedbackForm from '../feedback';
import HomepageHeroSearch from '../homepage/hero-search';
import initLazyLoadedStyles from '../lazyloading/styles';
import initTypeaheads from '../typeahead';

class Homepage extends GlobalContext {
    constructor() {
        super();
        this._initialiseComponents();
    }

    _initialiseComponents(): void {
        initLazyLoadedStyles();

        initTypeaheads();

        initFeedbackForm();

        const heroForm = document.querySelector<HTMLFormElement>( '.searchhero__form' );

        if ( heroForm ) {
            new HomepageHeroSearch( heroForm );
        }
    }
}

if ( !window.homepage ) {
    window.homepage = new Homepage();
}
