type LogLevel = 'debug' | 'info' | 'warn' | 'error';

interface LogMsg {
    msg: string;
    time: string;
    level: LogLevel;
}

export default class Logger {
    static debug( msg: string, data?: any ): void {
        this._log( 'debug', msg, false, data );
    }

    static info( msg: string, data?: any ): void {
        this._log( 'info', msg, false, data );
    }

    static warn( msg: string, data?: any ): void {
        this._log( 'warn', msg, false, data );
    }

    static error( msg: string, data?: any ): void {
        this._log( 'error', msg, false, data );
    }

    static store( level: LogLevel, msg: string, data?: any ): void {
        this._log( level, msg, true, data );
    }

    static _log( level: LogLevel, rawMsg: string, silent: boolean, data?: any ): void {
        let msg = rawMsg.trim();

        if ( /^\[[a-z]*\]/.test( msg ) === false ) {
            msg = `[app] ${msg}`;
        }

        const entry: LogMsg = {
            msg: msg,
            time: new Date().toISOString(),
            level: level,
        };

        if ( !window.appLogs ) {
            window.appLogs = [];
        }

        const logArgs = [ entry ];

        if ( data ) {
            logArgs.push( data );
        }

        window.appLogs.push( logArgs );

        if ( window.environment === 'testing' && !silent ) {
            if ( data ) {
                console[ level ]( entry.msg, data );
            } else {
                console[ level ]( entry.msg );
            }
        }
    }
}
