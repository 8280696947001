import { getUser } from './user';

const selector = '.property-telephone';

function toggleCountryCodes( container: Document | Element ): void {
    const numbers = container.querySelectorAll<HTMLElement>( selector );

    if ( numbers.length === 0 ) {
        return;
    }

    numbers.forEach( number => {
        if ( !number.dataset.altHref || number.classList.contains( 'property-telephone--updated' ) ) {
            return;
        }

        const href = number.getAttribute( 'href' );

        if ( href && href.length > 0 ) {
            number.setAttribute( 'href', `tel:${number.dataset.altHref}` );
            number.classList.add( 'no-country-code' );
        } else {
            number.innerHTML = number.dataset.altHref;

            if ( number.parentElement === null ) {
                throw new Error( 'parent element is null' );
            }

            number.parentElement.classList.add( 'no-country-code' );
        }

        number.classList.add( 'property-telephone--updated' );
    } );
}

async function initCountryCodes(): Promise<void> {
    const observer: IntersectionObserver = new IntersectionObserver( async ( entries ) => {
        if ( entries.some( entry => entry.isIntersecting ) ) {
            const user = getUser();

            if ( ( await user.visitorInfo() ).country === 'ES' ) {
                toggleCountryCodes( document );
                observer.disconnect();
            } else {
                observer.disconnect();
                window.removeEventListener( 'twc:newcontentloaded', onContentLoaded );
            }
        }
    } );

    const observe = function( elements: NodeListOf<HTMLElement> ): void {
        elements.forEach( ( element: HTMLElement ) => {
            if ( !/--(observed|updated)/.test( element.className ) ) {
                ( observer as IntersectionObserver ).observe( element );
                element.classList.add( 'property-telephone--observed' );
            }
        } );
    };

    const onContentLoaded = function( event: CustomEvent ) {
        let container = document;

        if ( event.detail && event.detail.container ) {
            container = event.detail.container;
        }

        observe( container.querySelectorAll( selector ) );
    };

    window.addEventListener( 'twc:newcontentloaded', onContentLoaded );

    observe( document.querySelectorAll<HTMLElement>( selector ) );
}

export default initCountryCodes;
