import { Suggestion } from './suggestion-types/common';
import { toOption } from './suggestion-types';

interface SearchHandlerOptions {
    limit: number;
    url: URL;
    test?: ( query: string ) => boolean;
    transform?: ( query: string ) => string;
}

class SearchHandler {
    _limit: number;
    _url: URL;
    _test?: ( query: string ) => boolean;
    _transform?: ( query: string ) => string;

    constructor( options: SearchHandlerOptions ) {
        this._limit = options.limit;
        this._url = options.url;
        this._test = options.test;
        this._transform = options.transform;
    }

    test( query: string ): boolean {
        if ( !this._test ) {
            return true;
        }
        return this._test( query );
    }

    async invoke( query: string ): Promise<Array<Suggestion>> {
        const url = this._url;

        query = query.trim();

        if ( this._transform ) {
            query = this._transform( query );
        }

        url.searchParams.set( 'q', query );
        url.searchParams.set( 'limit', this._limit.toString() );

        const response = await window.http( url );

        if ( !response.ok ) {
            throw new Error( 'typeahead search failed with status: ' + response.status );
        }

        const data = await response.json();

        if ( !data ) {
            return [];
        }

        return data.map( toOption );
    }
}

export default SearchHandler;
