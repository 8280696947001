import requestIdleCallback from '../shims/requestIdleCallback';

function updateSavedCounter( event ) {
    const detail = event.detail || {};

    if ( !detail.delta ) {
        return;
    }

    const delta = detail.delta;

    const counters = document.querySelectorAll<HTMLElement>( '.saved-counter' );

    if ( !counters ) {
        return;
    }

    let currentValue = parseInt( counters[ 0 ].innerHTML );

    if ( isNaN( currentValue ) ) {
        currentValue = 0;
    }

    counters.forEach( ( counter: HTMLElement ) => counter.innerHTML = ( currentValue + delta ) );
}

function persistUserSavedInSessionStorage( savedItems ) {
    const storageObjects = {
        savedProperties: {},
    };

    if ( !savedItems ) {
        Object.keys( storageObjects ).forEach( function( key ) {
            window.appStorage.setSessionItem( key, JSON.stringify( storageObjects[ key ] ) );
        } );
        return;
    }

    savedItems
        .filter( function( item ) {
            return item.property === 'property';
        } )
        .forEach( function( savedProperty ) {
            storageObjects.savedProperties[ parseInt( savedProperty.value ) ] = savedProperty;
        } );

    Object.keys( storageObjects ).forEach( function( key ) {
        window.appStorage.setSessionItem( key, JSON.stringify( storageObjects[ key ] ) );
    } );
}

function onUpdateSavedStatus( event: CustomEvent ): void {
    const detail = event.detail || {};
    requestIdleCallback( () => {
        updateSavedStatus( detail.container || document.body );
    } );
}

async function updateSavedStatus( container: HTMLElement ): Promise<void> {
    if ( !container ) {
        return;
    }

    const els = container.querySelectorAll( '.twc__savable, [data-saved-id]' );

    const data = await window.http( new URL( window.location.origin + '/saved/list' ) );

    if ( !data.ok ) {
        throw new Error( 'failed getting users saved list with status ' + data.status );
    }

    const response = await data.json();

    persistUserSavedInSessionStorage( response.saved );

    document.dispatchEvent( new CustomEvent( 'twc:saved-list-updated', {
        bubbles: true,
        detail: {
            items: response.saved,
        },
    } ) );

    const saved = response.saved || [];

    const count = saved.filter( s => !s.discarded ).length;

    document.querySelectorAll<HTMLElement>( '.saved-counter' ).forEach( ( counter: HTMLElement ) => counter.innerHTML = count );

    if ( els.length === 0 ) {
        return;
    }

    const saveableElements = container.querySelectorAll( '[data-saveable]' );

    for ( let saveableIndex = 0; saveableIndex < saveableElements.length; saveableIndex++ ) {
        const saveableElement = saveableElements[ saveableIndex ] as HTMLElement;

        const matchingSaved = saved.filter( function( s ) {
            return saveableElement.dataset.saveValue === s.value && saveableElement.dataset.saveProperty === s.property;
        } );

        if ( saveableElement.dataset.handler && saveableElement.dataset.handler.length > 0 ) {
            const handlerSplit = saveableElement.dataset.handler.split( ':' );

            const handler = {
                action: handlerSplit[ 0 ],
                type: handlerSplit[ 1 ],
                name: handlerSplit[ 2 ],
            };

            let required;

            if ( handler.action === 'save' && matchingSaved.length === 0 ) {
                required = true;
            } else if ( handler.action === 'save' && matchingSaved.length > 0 ) {
                required = false;
            }

            saveableElement.dataset.requiresHandler = required;
        }

        const replacable = saveableElement.querySelector( '.text' ) || saveableElement;

        if ( matchingSaved.length === 0 ) {
            replacable.innerHTML = saveableElement.dataset.saveSave || '';
            saveableElement.classList.remove( 'is-not-contacted' );
            saveableElement.classList.remove( 'favourite-alt' );
            saveableElement.classList.remove( 'favourite' );
            continue;
        }

        const foundSaved = matchingSaved[ 0 ];

        if ( foundSaved.contacted ) {
            saveableElement.innerHTML = saveableElement.dataset.saveContacted || '';
            saveableElement.classList.add( 'contacted' );
        } else {
            replacable.innerHTML = saveableElement.dataset.saveSaved || '';
            saveableElement.classList.remove( 'contacted' );

            if ( saveableElement.classList.contains( 'favourite-button' ) ) {
                saveableElement.classList.add( 'favourite' );
            } else {
                saveableElement.classList.add( 'favourite-alt' );
            }
        }
    }

    // now checking all the data-saved-id element for /user/saved page
    const saveIdsElements = container.querySelectorAll<HTMLElement>( '[data-saved-id]' );

    for ( let saveIdsIndex = 0; saveIdsIndex < saveIdsElements.length; saveIdsIndex++ ) {
        const saveIdElement = saveIdsElements[ saveIdsIndex ] as HTMLElement;

        const matchingUserSaved = saved.filter( function( s ) {
            return parseInt( saveIdElement.dataset.savedId || '' ) === s.id;
        } );

        if ( matchingUserSaved.length === 0 ) {
            continue;
        }

        const foundUserSaved = matchingUserSaved[ 0 ];

        if ( foundUserSaved.contacted ) {
            saveIdElement.classList.add( 'is-contacted' );
            saveIdElement.classList.remove( 'is-not-contacted' );
        } else {
            saveIdElement.classList.remove( 'is-contacted' );
            saveIdElement.classList.add( 'is-not-contacted' );
        }
    }
}

export {
    updateSavedStatus,
    updateSavedCounter,
    onUpdateSavedStatus,
};

