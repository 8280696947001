import { Suggestion } from './common';

import newNavigatableSuggestion from './link';
import newLocationSuggestion from './location';
import newSectionSuggestion from './section';
import newDrawYourSearchSuggestion from './draw-your-search';
import newCurrentLocationSuggestion from './current-location';
import newSimpleSuggestion from './simple';

export function toOption( item: any ): Suggestion {
    const type = item.suggestionType;

    if ( type === 'link' ) {
        return newNavigatableSuggestion( item );
    }

    if ( type === 'location' ) {
        return newLocationSuggestion( item );
    }

    if ( type === 'section' ) {
        return newSectionSuggestion( item );
    }

    if ( type === 'draw-your-search' ) {
        return newDrawYourSearchSuggestion( item );
    }

    if ( type === 'current-location' ) {
        return newCurrentLocationSuggestion( item );
    }

    return newSimpleSuggestion( item );
}
